import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';

interface WarningModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description: string;
  cancelButtonText?: string;
  buttonText: string;
  onCancel?: () => void;
  onSubmit: () => void;
}
export default function WarningModal({
  open,
  setOpen,
  title,
  description,
  cancelButtonText,
  buttonText,
  onCancel,
  onSubmit,
}: WarningModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="h-a z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col gap-4 rounded-3xl bg-secondary-50 p-10 py-6">
      <div className="flex flex-col">
        <h3 className="text-base font-semibold leading-6">{title}</h3>
        <p className="text-sm">{description}</p>
      </div>
      <div className="flex justify-end">
        <Button className="underline" onClick={onCancel ?? (() => setOpen(false))}>
          {cancelButtonText || t('general.cancel')}
        </Button>
        <Button variant="primary" className="px-[32px]" onClick={onSubmit}>
          {buttonText}
        </Button>
      </div>
    </ModalWrapper>
  );
}

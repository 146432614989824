import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import ModalWrapper from '../layouts/ModalWrapper';
import PrivacyText from '../misc/PrivacyText';

type PrivacyAndConditionsProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function PrivacyAndConditionsModal({ open, setOpen }: PrivacyAndConditionsProps): JSX.Element {
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="relative z-50 h-[600px] max-w-7xl rounded-[20px] bg-secondary-50 p-10 py-14">
      <div className="h-full overflow-auto">
        <PrivacyText />
      </div>
      <button onClick={() => setOpen(false)} type="button" className="absolute right-3 top-3 text-primary-900">
        <AiOutlinePlus className="h-7 w-7 rotate-45" />
      </button>
    </ModalWrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import { BusinessInvite } from '../../types/invites';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import { ProfileField } from '../../types/profile';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';
import { connectionService } from '../../services';
import { SuccessPageState } from '../../types/navigation';
import useEffectAfterFirstRender from '../../hooks/useEffectAfterFirstRender';
import useAuth from '../../hooks/useAuth';
import BusinessOverviewScreen from '../../components/overviews/BusinessOverviewScreen';

interface SharedWithCommunityMembersPageState {
  invite: BusinessInvite;
  success: SuccessPageState;
}

export default function SharedWithCommunityMembersPage(): JSX.Element {
  const location = useLocation();
  const { invite, success }: SharedWithCommunityMembersPageState = location.state ?? {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!invite || !success) {
      toast.error(t('toast.error.general.inviteNotFound'));
      navigate('/', { replace: true });
    }
  }, []);

  useEffectAfterFirstRender(() => {
    if (auth) return;
    toast.error(t('toast.error.general.loggedOut'));
    navigate('/', { replace: true });
  }, [JSON.stringify(auth)]);

  const requesterName = invite?.requesterAlias.split('+')[0] || '';

  const handleComplete = (): void => {
    if (setSelectedData.length === 0) setModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = () => {
    connectionService
      .updateDataSharedWithCommunityMembers(
        +invite.requesterId,
        selectedData.map((d) => +d.id!),
      )
      .then(() => {
        navigate('/success', { state: success, replace: true });
      });
  };

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.SidePanel>
          <BusinessOverviewScreen invite={invite} />
        </ResponsePageWrapper.SidePanel>
        <ResponsePageWrapper.Header>
          <InviteHeader
            requesterName={requesterName}
            message={
              invite.shareWithAdmins
                ? t('page.shared.successTitleAdmin', { name: requesterName })
                : t('page.shared.successTitle', { name: requesterName })
            }
          />
        </ResponsePageWrapper.Header>
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-medium">{t('page.shared.subTitle')}</h2>
            <p className="text-sm">{t('page.dataForCommunityMembers', { name: requesterName })}</p>
          </div>

          <UserDataForm selectedData={selectedData} setSelectedData={setSelectedData} hasSelectAll />

          <div className="flex h-full min-h-10 flex-1 items-end justify-end gap-2 align-bottom">
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={modalOpen}
        setOpen={setModalOpen}
        requesterName={requesterName}
        handleComplete={handleSubmit}
      />
    </>
  );
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import { authenticationService, connectionService, invitationService, profileService } from '../../services';
import {
  BusinessInvitePropertyType,
  BusinessVersion,
  CustomFieldResponses,
  CustomFieldType,
  InviteType,
  LocalContactInvite,
} from '../../types/invites';
import { ProfileDataType, ProfileField } from '../../types/profile';
import {
  addPhoneNumberCountryCode,
  createEmptyCustomFieldResponses,
  createEmptyField,
  formatCustomFieldResponsesFromCustomFields,
} from '../../utils/formatUtils';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import ProfileFieldForm from '../../components/forms/profile/ProfileFieldForm';
import EmailInput from '../../components/forms/profile/EmailInput';
import CommunicationNameInput from '../../components/forms/profile/CommunicationNameInput';
import NameInput from '../../components/forms/profile/NameInput';
import InviteHeader from '../../components/headers/InviteHeader';
import { sortProfileFields } from '../../utils/sortUtils';
import { checkMandatoryFields, isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import { profileDataTypeIcons } from '../../utils/iconUtils';
import { profileFieldToString } from '../../utils/stringUtils';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import Button from '../../components/misc/Button';
import { UserAccountCreationChannel, UserAccountCreationEvent } from '../../analytics/models';
import { SharedBusinessData } from '../../types/misc';
import AccountExistsModal from '../../components/modals/AccountExistsModal';

export default function UnauthenticatedLocalContactInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const invitationId = params.get('invitationId');
  const link = params.get('link');

  if (link) authenticationService.loginMagic(link);

  const [invite, setInvite] = useState<LocalContactInvite>();
  const [alias, setAlias] = useState<string>('');
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>(
    createEmptyCustomFieldResponses([]),
  );
  const [validate, setValidate] = useState<boolean>(false);

  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [accountExistsModal, setAccountExistsModal] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);

  const emailField = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL);

  useEffect(() => {
    if (!invitationId) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
    }
  }, []);

  useLayoutEffect(() => {
    if (!invitationId || link) return;

    const setFields = async () => {
      const inv =
        (location.state?.businessInvite as LocalContactInvite) ||
        (await invitationService.getLocalContactInvite(invitationId).catch(() => {
          toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }));
      if (!inv) return;
      setInvite(inv);

      const {
        mandatoryFields,
        optionalFields,
        customFields,
        data: { receivedData, alias: aliasInvite, customFieldResponses: cfr },
      } = inv;
      const dataRequest = Array.from(
        new Set([...mandatoryFields, ...optionalFields, BusinessInvitePropertyType.EMAIL]),
      );
      setProfileFields(
        sortProfileFields(
          dataRequest
            .map((f) => receivedData.find((x) => x.dataType === f || x.social?.socialType === f) || createEmptyField(f))
            .filter((x) => !!x) as ProfileField[],
        ),
      );
      setAlias(aliasInvite);
      setCustomFieldResponses(formatCustomFieldResponsesFromCustomFields(customFields, cfr));
    };

    setFields();
  }, []);

  const validateFields = (): boolean => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.field.name'));
      return false;
    }
    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }
    if (isEmptyField(profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!)) {
      toast.error(t('toast.error.field.email'));
      return false;
    }
    return isValidProfileFields(profileFields);
  };

  const handleSave = async () => {
    if (!invite) return;
    setValidate(true);
    if (!validateFields()) return;
    if (!checkMandatoryFields(invite!, profileFields, customFieldResponses)) return;
    if (accountExists) {
      setAccountExistsModal(true);
      return;
    }

    const event: UserAccountCreationEvent = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.LOCAL_CONTACT_INVITE],
      details: undefined,
    };
    const login = await authenticationService.register(alias, emailField?.email!, event);

    const properties = await profileService.addFields(
      profileFields
        .filter((x) => !isEmptyField(x) && x.dataType !== ProfileDataType.EMAIL)
        .map(addPhoneNumberCountryCode),
    );

    const args: SharedBusinessData = {
      propertyIds: properties
        .filter((f) => f.dataType !== ProfileDataType.EMAIL || !invite.nonRequestedFields.includes(f.dataType))
        .map((x) => x.id!),
      customFieldResponses,
      dataForMembers: [],
    };

    await connectionService.createLocalContactConnection(invitationId!, args);

    const state: SuccessPageState = {
      translationKey: `page.common.success.message.${
        invite?.version === BusinessVersion.COMMUNITY ? 'businessCommunityInvite' : 'businessInvite'
      }`,
      translationOptions: {
        businessName: invite?.requesterAlias.replace('+', ' '),
      },
      ...(invite?.version === BusinessVersion.COMMUNITY
        ? { businessInvite: invite! }
        : { requesterId: invite?.requesterId, requesterAlias: invite?.requesterAlias }),
    };

    if (invite.connectedMembers) navigate('/community/members', { state: { invite, success: state, replace: true } });
    else navigate('/success', { state });

    setTimeout(login, 1);
  };

  const handleLogin = async () => {
    const state: LoginPageState = {
      email: emailField?.email! || '',
      invitationId: invitationId!,
      inviteType: InviteType.LOCAL_CONTACT,
      businessInvite: invite!,
    };
    navigate('/login', { state });
  };

  const setProfileFieldByType = (field: ProfileField) => {
    if (field.dataType === ProfileDataType.SOCIAL) {
      setProfileFields(
        profileFields.map(
          (f: ProfileField): ProfileField => (f.social?.socialType === field.social?.socialType ? field : f),
        ),
      );
    } else
      setProfileFields(
        profileFields.map((f: ProfileField): ProfileField => (f.dataType === field.dataType ? field : f)),
      );
  };

  const requesterName = invite?.requesterAlias.split('+')[0] || '';
  const communicationName = invite?.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME);

  const handleMagicGeneration = async () => {
    await authenticationService.generateMagicLink(
      await invite?.data.receivedData.find((f) => f.dataType === ProfileDataType.EMAIL)?.email!,
      invitationId!,
      undefined,
      InviteType.LOCAL_CONTACT,
    );
    toast.success(t('toast.success.login'));
  };

  if (invite?.hasAccount)
    return (
      <ResponsePageWrapper handleLogin={handleLogin}>
        <ResponsePageWrapper.Header>
          <InviteHeader requesterName={requesterName || ''} message={invite?.message!} />
        </ResponsePageWrapper.Header>
        <div className="flex flex-col">
          <h2 className="text-lg font-medium">{t('page.localContactInvite.noAuthHasAccount.title')}</h2>
          <p className="text-sm">{t('page.localContactInvite.noAuthHasAccount.message')}</p>
        </div>

        <div className="mt-4 flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="flex h-fit items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
              <AiOutlineUser className="h-5 w-5" strokeWidth="3" />
            </div>
            <p className="my-auto text-sm">{invite?.data.alias.replace('+', ' ') || ''}</p>
          </div>
          {invite?.data.receivedData
            .filter((f) => !isEmptyField(f))
            .map((f) => (
              <div className="flex gap-2" key={f.dataType}>
                <div className="flex h-fit items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
                  {profileDataTypeIcons[f.dataType]}
                </div>
                <p className="my-auto text-sm">{profileFieldToString(f)}</p>
              </div>
            ))}
        </div>

        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button className="underline" onClick={() => navigate('/')}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleMagicGeneration}>
            {t('page.localContactInvite.noAuthHasAccount.button')}
          </Button>
        </div>
      </ResponsePageWrapper>
    );

  return (
    <>
      <ResponsePageWrapper handleLogin={handleLogin}>
        <ResponsePageWrapper.Header>
          <InviteHeader requesterName={requesterName || ''} message={invite?.message!} />
        </ResponsePageWrapper.Header>

        <div className="flex flex-col">
          <h2 className="text-lg font-medium">{t('page.shared.shareData')}</h2>
          <p className="text-sm">{t('page.localContactInvite.noAuthNoAccount.message', { name: requesterName })}</p>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <NameInput alias={alias} setAlias={setAlias} mandatory validate={validate} />

          {communicationName && (
            <CommunicationNameInput
              name={customFieldResponses[communicationName.customFieldId]}
              validate={validate}
              setName={(name) =>
                setCustomFieldResponses({
                  ...customFieldResponses,
                  [communicationName.customFieldId]: name,
                })
              }
              mandatory={communicationName?.mandatory}
            />
          )}

          {emailField && (
            <EmailInput
              field={emailField!}
              emailCheck
              validate={validate}
              mandatory
              setAccountExists={setAccountExists}
              setField={setProfileFieldByType}
            />
          )}
          {accountExists ? (
            <span className="text-sm text-error">
              {t('page.shared.emailInUse')}{' '}
              <strong className="cursor-pointer underline" onClick={handleLogin}>
                {t('page.shared.logInHere')}
              </strong>
              .
            </span>
          ) : (
            ''
          )}

          {profileFields.map((field) => {
            return field.dataType === ProfileDataType.EMAIL ? (
              <></>
            ) : (
              <ProfileFieldForm
                field={field}
                canBeEmpty={!invite?.mandatoryFields.includes(field.dataType)}
                validate={validate}
                mandatory={invite?.mandatoryFields.includes(field.dataType)}
                setField={setProfileFieldByType}
              />
            );
          })}
          <CustomFieldsForm
            fields={invite?.customFields || []}
            response={customFieldResponses}
            setResponse={setCustomFieldResponses}
          />
        </div>
        <div className="mt-4">
          <TermsAndConditions checked={terms} setChecked={setTerms} name={requesterName} />
        </div>
        <div className="flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button variant="primary" className="px-[32px]" onClick={handleSave}>
            {t('page.shared.acceptInvite')}
          </Button>
        </div>
      </ResponsePageWrapper>
      {emailField?.email && invitationId && (
        <AccountExistsModal
          open={accountExistsModal}
          setOpen={setAccountExistsModal}
          request={{
            inviteType: InviteType.LOCAL_CONTACT,
            invitationId,
            email: emailField.email,
          }}
        />
      )}
    </>
  );
}

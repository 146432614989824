import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavBarLogo from '../../components/misc/NavBarLogo';
import { Language } from '../../types/requests';
import { setLanguage } from '../../redux/slices/applicationSlice';
import useLanguage from '../../hooks/useLanguage';
import Footer from '../../components/misc/Footer';
import DownloadButton from '../../components/misc/DownloadButton';

export default function AccountPage(): JSX.Element {
  const dispatch = useDispatch();
  const lang = useLanguage();
  const { t } = useTranslation();
  return (
    <>
      <div className="sticky top-0 z-50 flex h-16 w-full items-center rounded-b-[20px] bg-secondary-200 px-4">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between">
          <NavBarLogo />
          <img
            src={`${process.env.PUBLIC_URL}/images/flags/${lang === Language.NL ? Language.EN : Language.NL}.svg`}
            alt="Nederlands"
            onClick={() => dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))}
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      </div>
      <div className="flex min-h-[calc(100vh-128px)] w-full flex-1 flex-col items-center bg-secondary-50 py-10">
        <div className="w-full max-w-5xl">
          <h2 className="mb-2 text-4xl font-medium">{t('page.account.about.title')}</h2>
          <p>{t('page.account.about.text')}</p>
          <div className="mt-4">
            <DownloadButton />
          </div>
          <h2 className="mb-2 mt-8 text-4xl font-medium">{t('page.account.delete.title')}</h2>
          <p>
            {t('page.account.delete.text')}
            <a
              href="mailto:?to=info@veraconnect.nl&subject=Verzoek tot verwijderen van account"
              target="_blank"
              rel="noreferrer"
              className="font-medium underline">
              info@veraconnect.nl
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

/* eslint-disable no-useless-return */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ProfileField } from '../../types/profile';
import { BusinessVersion, CrmUserInvite, CustomFieldResponses, CustomFieldType } from '../../types/invites';
import { connectionService, invitationService } from '../../services';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import { checkMandatoryFields, isEmptyField } from '../../utils/validationUtils';
import useProfileFields from '../../hooks/useProfileFields';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import useDeleteParams from '../../hooks/useDeleteParams';
import { SuccessPageState } from '../../types/navigation';
import { createEmptyCustomFieldResponses } from '../../utils/formatUtils';
import CommunicationNameInput from '../../components/forms/profile/CommunicationNameInput';
import AddFieldsHeader from '../../components/headers/AddFieldsHeader';
import { filterPresentFields } from '../../utils/filterUtils';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';
import { SharedBusinessData } from '../../types/misc';
import NoRequestedDataCommunityInvitePage from '../reusable/NoRequestedDataCommunityInvitePage';

export default function AuthenticatedCrmUserInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  useDeleteParams('link');

  const token = params.get('invitationId');

  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>(
    createEmptyCustomFieldResponses([]),
  );
  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invite, setInvite] = useState<CrmUserInvite>();
  const [validate, setValidate] = useState<boolean>(false);
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState(false);

  useEffect(() => {
    if (!token) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }

    invitationService
      .getCrmUserInvite(token)
      .then((inv) => {
        setInvite({ ...inv, receivedData: inv.receivedData.map((f) => ({ ...f, id: v4() })) });
        const preSelectedValues = filterPresentFields(
          profileFields,
          inv.receivedData.filter((f) => !isEmptyField(f)),
        );

        setSelectedData(preSelectedValues);
        setCustomFieldResponses(createEmptyCustomFieldResponses(inv.customFields));
      })

      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  const fetchedSharedData = useRef(false);
  useEffect(() => {
    if (!invite || profileFields.length === 0 || fetchedSharedData.current) return;
    fetchedSharedData.current = true;

    connectionService
      .getSharedDataWithBusiness(invite.requesterId)
      .then((): void => {
        navigate('/success', {
          state: {
            translationKey: 'page.common.success.message.existingConnection',
            translationOptions: {
              requesterName: invite.requesterAlias,
            },
            businessInvite: invite,
          },
        });
      })
      .catch((): void => {});
  }, [invite, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    setValidate(true);
    if (!checkMandatoryFields(invite!, selectedData, customFieldResponses)) return;
    const args: SharedBusinessData = {
      propertyIds: selectedData.map((x): string => x.id!),
      customFieldResponses,
      dataForMembers: [],
    };

    await connectionService.createCrmUserConnection(token!, args);

    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.businessCommunityInvite',
      translationOptions: {
        businessName: invite?.requesterAlias.replace('+', ' ') || '',
      },
      ...(invite?.version === BusinessVersion.COMMUNITY
        ? { businessInvite: invite! }
        : { requesterId: invite?.requesterId, requesterAlias: invite?.requesterAlias }),
    };

    if (invite?.connectedMembers) navigate('/community/members', { state: { invite, success: state }, replace: true });
    else {
      navigate('/success', { state });
      toast.success(t('toast.success.connectionCreated'));
    }
  };

  const addFields = (fields: ProfileField[]): void => {
    setSelectedData([...selectedData.filter((f) => !fields.some((x) => x.dataType === f.dataType)), ...fields]);
  };

  if (invite && !invite.shareWithAdmins)
    return <NoRequestedDataCommunityInvitePage invite={invite!} onSubmit={handleSubmit} />;

  const requesterName = invite?.requesterAlias.split('+')[0] || '';
  const communicationName = invite?.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME);

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <AddFieldsHeader
            requesterName={requesterName}
            message={invite?.message!}
            fields={invite?.receivedData}
            addFields={addFields}
          />
        </ResponsePageWrapper.Header>
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-medium">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">{t('page.businessInvite.message', { name: requesterName })}</p>
          </div>

          {communicationName && (
            <div className="border-panel-broder border-b pb-4">
              <h2 className="mb-1 border-t border-secondary-200 pt-4 text-lg font-medium">
                {t('form.communicationName.label')} {communicationName?.mandatory && '*'}
              </h2>
              <CommunicationNameInput
                name={customFieldResponses[communicationName.customFieldId]}
                validate={validate}
                setName={(name) =>
                  setCustomFieldResponses({
                    ...customFieldResponses,
                    [communicationName.customFieldId]: name,
                  })
                }
                mandatory={communicationName?.mandatory}
              />
            </div>
          )}
          <UserDataForm
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            mandatoryFields={invite?.mandatoryFields || []}
            nonRequestedFields={invite?.nonRequestedFields || []}
            onePerDataType={invite?.version === BusinessVersion.COMMUNITY}
            onlyBirthDate
          />

          <div>
            {invite?.customFields && invite?.customFields.length > 0 && (
              <>
                <h2 className="mb-1 border-t border-secondary-200 pt-4 text-lg font-medium">
                  {t('page.shared.requestedFields')}
                </h2>
                <CustomFieldsForm
                  fields={invite?.customFields || []}
                  response={customFieldResponses}
                  setResponse={setCustomFieldResponses}
                />
              </>
            )}
          </div>
          <div className="flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={requesterName}
        handleComplete={handleSubmit}
      />
    </>
  );
}

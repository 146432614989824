import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useDeleteParams from '../../hooks/useDeleteParams';
import { authenticationService, eventService } from '../../services';
import { Event, EventParticipationAnswer } from '../../types/Events';
import { CustomFieldResponses, InviteType } from '../../types/invites';
import { formatCustomFieldResponsesFromCustomFields } from '../../utils/formatUtils';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import EventOverview from '../../components/overviews/EventOverview';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import Button from '../../components/misc/Button';
import EventSelectDateOption from '../../components/forms/events/EventSelectDateOption';
import NameInput from '../../components/forms/profile/NameInput';
import EmailInput from '../../components/forms/profile/EmailInput';
import { Label, ProfileDataType } from '../../types/profile';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import { InvitationDetails, UserAccountCreationChannel, UserAccountCreationEvent } from '../../analytics/models';
import AccountExistsModal from '../../components/modals/AccountExistsModal';

function AuthenticatedEventRespondPage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useDeleteParams('link');

  const linkId = params.get('id');

  const [event, setEvent] = useState<Event>();
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);
  const [hasGivenAvailability, setHasGivenAvailability] = useState<boolean>(false);
  const [dateOptionsUpdate, setDateOptionsUpdate] = useState<
    Record<number, { answer: EventParticipationAnswer; description?: string }>
  >({});

  const [email, setEmail] = useState<string>('');
  const [alias, setAlias] = useState<string>('');
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [accountExistsModal, setAccountExistsModal] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [validateFields, setValidateFields] = useState<boolean>(false);

  useEffect(() => {
    eventService
      .getEventInviteWithToken(linkId as string)
      .then((e) => setEvent(e))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  useEffect(() => {
    try {
      if (!event) return;

      if (!event.isDatePicker) {
        navigate(`/event/invite?id=${event.id}`);
      }

      const responses: { [p: string]: any } = [[]];
      setCustomFieldResponses(formatCustomFieldResponsesFromCustomFields(event!.customFields, responses));
      setHasGivenAvailability(false);
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  const validate = () => {
    const throwError = (message: string) => {
      toast.error(message);
      return false;
    };

    setValidateFields(true);

    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) return throwError(t('toast.error.field.mandatoryStar'));

    if (!email) return throwError(t('toast.error.field.mandatoryStar'));

    if (!hasGivenAvailability && Object.keys(dateOptionsUpdate).length !== event!.dateOptions.length)
      return throwError(t('page.events.invite.datePickers.missingOptions'));

    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }

    if (event!.customFields.length > 0) {
      return event!.customFields.every((field) => {
        if (field.mandatory && !customFieldResponses[field.customFieldId]) {
          toast.error(t('toast.error.field.mandatoryStar'));
          return false;
        }
        return true;
      });
    }

    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate() || !event?.id) return;
    if (accountExists) {
      setAccountExistsModal(true);
      return;
    }

    const dateOptions = Object.entries(dateOptionsUpdate).map(([dateOptionId, { answer, description }]) => ({
      dateOptionId: parseInt(dateOptionId, 10),
      answer,
      description,
    }));

    const registerEvent: UserAccountCreationEvent<InvitationDetails> = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.DATE_PICKER_INVITE],
      details: { inviteLink: linkId || '' },
    };

    const login = await authenticationService.register(alias, email, registerEvent);

    await eventService.respondToEventDateOptionBulk(`${event.id}`, dateOptions);

    if (event!.customFields.length > 0) {
      await eventService.respondToEventPollBulk(`${event.id}`, customFieldResponses);
    }

    const state: SuccessPageState = { translationKey: 'page.events.invite.message.success' };
    navigate('/success', { state });
    setTimeout(login, 1);
  };

  if (!event) return <></>;

  const handleLogin = async () => {
    const state: LoginPageState = { inviteType: InviteType.DATE_PICKER, eventId: `${event.id}`, hideBackButton: true };
    navigate('/login', { state });
  };

  const { deadline } = event;

  const deadlineIsPassed = deadline ? new Date() > new Date(deadline) : false;

  return (
    <>
      <ResponsePageWrapper handleLogin={handleLogin}>
        <EventOverview event={event}>
          {!deadlineIsPassed && (
            <div className="flex flex-col gap-4">
              <div className="font-serif text-lg">{t('page.events.invite.datePickers.giveAvailability')}</div>

              {event.dateOptions.map((dateOption) => (
                <EventSelectDateOption
                  key={dateOption.id}
                  dateOption={dateOption}
                  eventId={+event.id}
                  dateOptionAnswer={dateOptionsUpdate[dateOption.id]}
                  onSave={(__, dateOptionId, answer, description) => {
                    const update = { [dateOptionId]: { answer, description } };
                    setDateOptionsUpdate((prev) => ({ ...prev, ...update }));
                  }}
                />
              ))}

              {event.customFields.length > 0 && (
                <>
                  <div className="font-serif text-lg">{t('page.events.invite.polls.title')}</div>
                  <CustomFieldsForm
                    fields={event.customFields}
                    response={customFieldResponses}
                    setResponse={setCustomFieldResponses}
                  />
                </>
              )}
            </div>
          )}
        </EventOverview>

        {!deadlineIsPassed && (
          <div className="my-4 flex flex-col gap-4">
            <div className="font-serif text-3xl">{t('page.events.invite.register')}</div>

            <NameInput alias={alias} setAlias={setAlias} mandatory validate={validateFields} />

            <EmailInput
              field={{
                dataType: ProfileDataType.EMAIL,
                email,
                label: Label.GENERAL,
                description: '',
              }}
              emailCheck
              setAccountExists={setAccountExists}
              setField={(field) => setEmail(field.email!)}
              mandatory
              validate={validateFields}
            />
            {accountExists && (
              <span className="text-sm text-error">
                {t('page.shared.emailInUse')}{' '}
                <strong className="cursor-pointer underline" onClick={handleLogin}>
                  {t('page.shared.logInHere')}
                </strong>
                .
              </span>
            )}
          </div>
        )}

        {!deadlineIsPassed && <TermsAndConditions className="mt-4" checked={terms} setChecked={setTerms} />}

        {!deadlineIsPassed && (
          <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
            <Button className="underline" onClick={() => navigate('/')}>
              {t('general.cancel')}
            </Button>
            <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
              {t('general.save')}
            </Button>
          </div>
        )}
      </ResponsePageWrapper>
      {email && (
        <AccountExistsModal
          open={accountExistsModal}
          setOpen={setAccountExistsModal}
          request={{
            inviteType: InviteType.DATE_PICKER,
            eventId: `${event.id}`,
            email,
          }}
        />
      )}
    </>
  );
}

export default AuthenticatedEventRespondPage;

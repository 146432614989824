import classNames from 'classnames';
import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

interface QuestionCircleProps {
  text: string;
  relative?: boolean;
  position?: string;
}
// WARNING this thing doesnt work completely because it shouldnt take up full width but it works in the place where it is used
export default function QuestionCircle({ text, relative = true, position = '' }: QuestionCircleProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className={classNames('inline-block w-full justify-start transition-all', { relative })}>
      <FaQuestionCircle onMouseEnter={handleHover} onMouseLeave={handleHover} className="h-3 w-3" />

      <div
        className={`${
          isHovered ? 'opacity-100' : 'invisible opacity-0'
        } absolute z-50 flex w-40 min-w-24 rounded bg-black p-2 text-xs text-white transition-all duration-300 ${position || '-left-1 top-5'}`}>
        {text}
      </div>
    </div>
  );
}

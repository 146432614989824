import React from 'react';
import { useTranslation } from 'react-i18next';

interface InviteHeaderProps {
  requesterName: string;
  message?: string;
}
export default function DataRequestHeader({ requesterName, message = '' }: InviteHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="font-serif text-2xl font-medium">
        {message || t('header.dataRequest.title', { name: requesterName })}
      </h1>
      <div className="flex">
        <img src="/images/misc/shield.svg" alt="Onboarding visual" className="mr-2 mt-[3px] h-5 w-5 object-cover" />
        <p className="h-fit w-fit text-sm font-light">{t('header.invalidAuth.message')}</p>
      </div>
    </>
  );
}

import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PhoneLayoutProps {
  children: React.ReactNode;
  className?: string;
}
export default function PhoneLayout({ children, className }: PhoneLayoutProps): JSX.Element {
  return (
    <div>
      <div className="relative h-[600px] w-[300px] rounded-[2.5rem] border-[14px] border-gray-800">
        <div className="absolute -left-[17px] top-[72px] z-[2] h-[32px] w-[3px] rounded-l-lg bg-gray-800" />
        <div className="absolute -left-[17px] top-[124px] z-[2] h-[46px] w-[3px] rounded-l-lg bg-gray-800" />
        <div className="absolute -left-[17px] top-[178px] z-[2] h-[46px] w-[3px] rounded-l-lg bg-gray-800" />
        <div className="absolute -right-[16px] top-[142px] z-[2] h-[64px] w-[3px] rounded-r-lg bg-gray-800" />
        <div className="absolute left-0 right-0 top-[10px] z-[2] mx-auto h-[20px] w-[84px] rounded-full bg-gray-800" />
        <div className={twMerge('no-scrollbar h-full w-full overflow-scroll rounded-[26px]', className)}>
          {children}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { communityService } from '../../services';
import { BusinessInvite, CommunityInvite } from '../../types/invites';
import { fromCommunityDataToCommunityInvite } from '../../utils/formatUtils';
import CommunityOverviewScreen from './CommunityOverviewScreen';
import ContactsOverviewScreen from './ContactsOverviewScreen';

interface BusinessOverviewScreenProps {
  invite?: BusinessInvite;
}
export default function BusinessOverviewScreen({ invite }: BusinessOverviewScreenProps): JSX.Element {
  if (!invite) return <></>;
  const [communityInvite, setCommunityInvite] = useState<CommunityInvite | null>();

  useEffect(() => {
    communityService
      .getByBusinessId(invite.requesterId)
      .then((res) => setCommunityInvite(res && fromCommunityDataToCommunityInvite(res)));
  }, []);

  if (communityInvite === undefined) return <></>;
  return communityInvite ? <CommunityOverviewScreen invite={communityInvite} /> : <ContactsOverviewScreen />;
}

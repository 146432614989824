import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDataType } from '../../types/profile';
import { getInitials } from '../../utils/stringUtils';
import { profileDataTypeIcons } from '../../utils/iconUtils';
import { sortProfileDataTypes } from '../../utils/sortUtils';

interface BusinessCardHeaderProps {
  requesterAlias: string;
  providedTypes: ProfileDataType[];
  profilePicture: string;
}

export default function BusinessCardHeader({
  requesterAlias,
  providedTypes,
  profilePicture,
}: BusinessCardHeaderProps): JSX.Element {
  const [firstName, lastName] = requesterAlias.split('+');
  const { t } = useTranslation();

  const getSecretStringForDataType = (dataType: ProfileDataType): string => {
    switch (dataType) {
      case ProfileDataType.PHONENUMBER:
        return '+31* *** *** **';
      case ProfileDataType.EMAIL:
        return '*********@*****.***';
      case ProfileDataType.ADDRESS:
        return '*************** **\n****** *****\n*********';
      case ProfileDataType.BIRTHDATE:
      case ProfileDataType.MOMENT:
        return '** - ** - ****';
      case ProfileDataType.BUSINESSNAME:
        return '******** **';
      case ProfileDataType.SOCIAL:
        return 'www.************.com';
      default:
        return '**********';
    }
  };

  return (
    <>
      <h1 className="font-serif text-2xl font-medium">{t('header.businessCard.title', { name: firstName })}</h1>
      <div className="flex">
        <img src="/images/misc/shield.svg" alt="Onboarding visual" className="mr-2 mt-[3px] h-5 w-5 object-cover" />
        <p className="h-fit w-fit text-sm font-light">{t('header.message', { name: firstName })}</p>
      </div>
      <div className="mt-2 flex h-fit w-full flex-col gap-4 rounded-xl bg-secondary-200 p-3 pb-4">
        <div className="flex w-full items-center gap-2">
          {profilePicture ? (
            <img src={profilePicture} alt={firstName} className="h-12 w-12 rounded-lg object-cover" />
          ) : (
            <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-secondary text-xl text-secondary-50">
              {getInitials(requesterAlias)}
            </div>
          )}
          <h2 className="text-lg font-medium">{`${firstName} ${lastName}`}</h2>
        </div>
        <div className="flex flex-col gap-2 px-6">
          {sortProfileDataTypes(providedTypes).map((type) => (
            <div key={type} className="flex gap-2">
              <div className="flex h-fit items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
                {profileDataTypeIcons[type as keyof typeof profileDataTypeIcons]}
              </div>
              <div className="my-auto flex flex-col gap-1">
                {getSecretStringForDataType(type)
                  .split('\n')
                  .map((line) => (
                    <p className="w-fit rounded-md bg-secondary-50 text-sm text-secondary-50">{line}</p>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { FaBuilding, FaFacebookSquare, FaGlassCheers, FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { FaCakeCandles, FaXTwitter } from 'react-icons/fa6';
import { HiOutlineCalendar } from 'react-icons/hi';
import { FiPhone, FiMapPin, FiLink } from 'react-icons/fi';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { MomentType, ProfileDataType, SocialType } from '../types/profile';

export const profileDataTypeIcons = {
  [ProfileDataType.ADDRESS]: <FiMapPin className="icon h-5 w-5" aria-hidden="true" strokeWidth="2" />,
  [ProfileDataType.BIRTHDATE]: <FaCakeCandles className="icon h-5 w-5" aria-hidden="true" />,
  [ProfileDataType.EMAIL]: <HiOutlineEnvelope className="icon h-5 w-5" strokeWidth="2" aria-hidden="true" />,
  [ProfileDataType.PHONENUMBER]: <FiPhone className="h-5 w-5" strokeWidth="2" aria-hidden="true" />,
  [ProfileDataType.BUSINESSNAME]: <FaBuilding className="h-5 w-5" strokeWidth="2" aria-hidden="true" />,
  [ProfileDataType.MOMENT]: <HiOutlineCalendar className="icon h-5 w-5" strokeWidth="2" aria-hidden="true" />,

  [ProfileDataType.SOCIAL]: <FiLink className="icon h-5 w-5" strokeWidth="2" aria-hidden="true" />,
};

export const momentTypeIcons = {
  [MomentType.CHILDBIRTH]: <FaCakeCandles className="icon h-5 w-5" aria-hidden="true" />,
  [MomentType.WEDDING]: <FaGlassCheers className="icon h-5 w-5" aria-hidden="true" />,
};

export const socialTypeIcons = {
  [SocialType.FACEBOOK]: <FaFacebookSquare className="icon h-5 w-5" aria-hidden="true" />,
  [SocialType.LINKEDIN]: <FaLinkedin className="icon h-5 w-5" aria-hidden="true" />,
  [SocialType.X]: <FaXTwitter className="icon h-5 w-5" aria-hidden="true" />,
  [SocialType.TIKTOK]: <FaTiktok className="icon h-5 w-5" aria-hidden="true" />,
  [SocialType.INSTAGRAM]: <FaInstagram className="icon h-5 w-5" aria-hidden="true" />,
};

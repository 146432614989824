import React, { useLayoutEffect, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import UnauthenticatedInvitePage from '../pages/unauthenticated/UnauthenticatedInvitePage';
import UnauthenticatedBusinessInvitePage from '../pages/unauthenticated/UnauthenticatedBusinessInvitePage';
import LoginPage from '../pages/unauthenticated/LoginPage';
import AuthenticatedBusinessInvitePage from '../pages/authenticated/AuthenticatedBusinessInvitePage';
import AuthenticatedInvitePage from '../pages/authenticated/AuthenticatedInvitePage';
import SuccessPage from '../pages/common/SuccessPage';
import NotFoundPage from '../pages/common/NotFoundPage';
import RedirectPage from '../pages/common/RedirectPage';
import { detectMobileOS } from '../utils/userAgentUtils';
import { MobileOS } from '../types/misc';
import UnauthenticatedLocalContactInvitePage from '../pages/unauthenticated/UnauthenticatedLocalContactInvitePage';
import AuthenticatedLocalContactInvitePage from '../pages/authenticated/AuthenticatedLocalContactInvitePage';
import UnauthenticatedPersonalInvitePage from '../pages/unauthenticated/UnauthenticatedPersonalInvitePage';
import AuthenticatedPersonalInvitePage from '../pages/authenticated/AuthenticatedPersonalInvitePage';
import InviteRedirectPage from '../pages/common/InviteRedirectPage';
import AboutPage from '../pages/common/AboutPage';
import AccountPage from '../pages/common/AccountPage';
import AuthenticatedPersonalLinkPage from '../pages/authenticated/AuthenticatedPersonalLinkPage';
import UnauthenticatedPersonalLinkPage from '../pages/unauthenticated/UnauthenticatedPersonalLinkPage';
import DataRequestPage from '../pages/common/app/DataRequestPage';
import CommunityContactInvitePage from '../pages/common/app/CommunityContactInvitePage';
import IntroductionsPage from '../pages/common/app/IntroductionsPage';
import CommunityRequestPage from '../pages/common/app/CommunityRequestPage';
import EmailPreferencesPage from '../pages/common/app/EmailPreferencesPage';
import PrivacyPage from '../pages/common/PrivacyPage';
import ConnectionInvitePage from '../pages/common/app/ConnectionInvitePage';
import SharedWithCommunityMembersPage from '../pages/authenticated/SharedWithCommunityMembersPage';
import EventRespondPage from '../pages/common/app/EventRespondPage';
import DatePickerRespondPage from '../pages/common/app/DatePickerRespondPage';
import AuthenticatedEventRespondPage from '../pages/authenticated/AuthenticatedEventRespondPage';
import AuthenticatedDatePickerRespondPage from '../pages/authenticated/AuthenticatedDatePickerRespondPage';
import UnauthenticatedEventRespondPage from '../pages/unauthenticated/UnauthenticatedEventRespondPage';
import UnauthenticatedDatePickerRespondPage from '../pages/unauthenticated/UnauthenticatedDatePickerRespondPage';
import DashboardPage from '../pages/common/DashboardPage';
import MagicLoginpage from '../pages/common/MagicLoginpage';
import { redirectComWebsite } from '../utils/appUtils';
import UnauthenticatedCrmUserInvitePage from '../pages/unauthenticated/UnauthenticatedCrmUserInvitePage';
import AuthenticatedCrmUserInvitePage from '../pages/authenticated/AuthenticatedCrmUserInvitePage';

function Router(): JSX.Element {
  const auth = useAuth();
  const mobileOS = detectMobileOS();
  const location = useLocation();

  useLayoutEffect(() => {
    if (mobileOS === MobileOS.ANDROID) redirectToApp();
    redirectToComWebsite();
  }, [location.pathname]);

  const redirectToApp = async () => {
    const url = window.location.pathname + window.location.search;
    if (window.location.pathname === '/redirect') return;
    const link = `vera:/${url}`;
    window.location.replace(link);
  };

  const redirectToComWebsite = async () => {
    switch (window.location.pathname) {
      case '/about':
        redirectComWebsite('/about');
        break;
      case '/account':
        redirectComWebsite('/account');
        break;
      case '/privacy-statement':
        redirectComWebsite('/privacy-statement');
        break;
      default:
        break;
    }
  };

  return (
    <Routes>
      {(auth ? authRoutes : noAuthRoutes).map((route) => (
        <Route key={route.path} {...route} />
      ))}
      <Route path="/magic" element={<MagicLoginpage />} />
      <Route path="/vc/:linkId" element={<InviteRedirectPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/data-request" element={<DataRequestPage />} />
      <Route path="/event" element={<EventRespondPage />} />
      <Route path="/date-picker" element={<DatePickerRespondPage />} />
      <Route path="/email-preferences" element={<EmailPreferencesPage />} />
      <Route path="/group/contact" element={<CommunityContactInvitePage />} />
      <Route path="/group/connect" element={<CommunityRequestPage />} />
      <Route path="/community/members" element={<SharedWithCommunityMembersPage />} />
      <Route path="/introductions" element={<IntroductionsPage />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/privacy-statement" element={<PrivacyPage />} />
      <Route path="connectionInvite" element={<ConnectionInvitePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default function WrapperRouter(): JSX.Element {
  return (
    <Suspense fallback="">
      <Router />
    </Suspense>
  );
}

const authRoutes = [
  {
    path: '/',
    element: <DashboardPage />,
  },
  {
    path: '/invite',
    element: <AuthenticatedInvitePage />,
  },
  {
    path: '/moments/invite',
    element: <AuthenticatedBusinessInvitePage />,
  },
  {
    path: '/moments/local-invite',
    element: <AuthenticatedLocalContactInvitePage />,
  },
  {
    path: '/personal/invite',
    element: <AuthenticatedPersonalInvitePage />,
  },
  {
    path: '/personalLink',
    element: <AuthenticatedPersonalLinkPage />,
  },
  {
    path: '/event/invite',
    element: <AuthenticatedEventRespondPage />,
  },
  {
    path: '/date-picker/invite',
    element: <AuthenticatedDatePickerRespondPage />,
  },
  {
    path: '/crm/invite',
    element: <AuthenticatedCrmUserInvitePage />,
  },
];

const noAuthRoutes = [
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: '/invite',
    element: <UnauthenticatedInvitePage />,
  },
  {
    path: '/moments/invite',
    element: <UnauthenticatedBusinessInvitePage />,
  },
  {
    path: '/moments/local-invite',
    element: <UnauthenticatedLocalContactInvitePage />,
  },
  {
    path: '/personal/invite',
    element: <UnauthenticatedPersonalInvitePage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/personalLink',
    element: <UnauthenticatedPersonalLinkPage />,
  },
  {
    path: '/event/invite',
    element: <UnauthenticatedEventRespondPage />,
  },
  {
    path: '/date-picker/invite',
    element: <UnauthenticatedDatePickerRespondPage />,
  },
  {
    path: '/crm/invite',
    element: <UnauthenticatedCrmUserInvitePage />,
  },
];

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

interface ModalLayoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
  children?: React.ReactNode;
}

export default function ModalWrapper({ open, setOpen, className, children = null }: ModalLayoutProps): JSX.Element {
  const focusRef = React.useRef(null);
  return (
    <Transition.Root data-testid="postcard-modal" show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen} initialFocus={focusRef}>
        <input className="hidden" ref={focusRef} />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-primary-900 bg-opacity-60 backdrop-blur-sm backdrop-filter transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className={className}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authenticationService } from '../../services';

export default function MagicLoginpage() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const link = params.get('link');

  useEffect(() => {
    authenticationService
      .loginMagic(link || '')
      .catch(() => {
        toast.error(t('toast.error.login'));
      })
      .finally(() => navigate('/'));
  }, []);

  return <></>;
}

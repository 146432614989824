import { CommunityData, CommunityMember } from '../types/misc';
import httpService from './httpService';

export function getCommunityMembers(communityId: number): Promise<CommunityMember[]> {
  return httpService.get<CommunityMember[]>(`/communities/${communityId}/members`).then(({ data }) => data);
}

export function getByBusinessId(businessId: string): Promise<CommunityData | null> {
  return httpService
    .get<CommunityData>(`/communities/business/${businessId}`)
    .then(({ data }) => data)
    .catch(() => null);
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isEmptyField } from '../../utils/validationUtils';
import Field from '../forms/userData/Field';
import { sortProfileFields } from '../../utils/sortUtils';
import { ProfileField } from '../../types/profile';
import useProfileFields from '../../hooks/useProfileFields';
import { filterUniqueProfileFields } from '../../utils/filterUtils';
import { profileService } from '../../services';
import InviteHeader from './InviteHeader';
import { addPhoneNumberCountryCode } from '../../utils/formatUtils';
import Button from '../misc/Button';

interface InviteHeaderProps {
  requesterName: string;
  message?: string;
  fields: ProfileField[] | undefined;
  addFields: (data: ProfileField[]) => void;
}
export default function AddFieldsHeader({
  requesterName,
  message = '',
  fields,
  addFields,
}: InviteHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const profileFields = useProfileFields();
  const formFields = sortProfileFields(filterUniqueProfileFields(profileFields, fields || []))
    .filter((f) => !f.social?.socialType || !profileFields.find((pf) => f.social?.socialType === pf.social?.socialType))
    .filter((x) => !isEmptyField(x));

  const [selectedFields, setSelectedFields] = useState<ProfileField[]>([]);
  const [show, setShow] = useState(true);

  const handleFieldClick = (active: boolean, field: ProfileField): void => {
    if (active) {
      setSelectedFields(selectedFields.filter((x) => x.id !== field.id));
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };

  const handleAddFields = async (): Promise<void> => {
    const newFields = await profileService.addFields(selectedFields.map(addPhoneNumberCountryCode));
    setSelectedFields([]);
    addFields(newFields);
    toast.success(t('toast.success.addedField'));
  };

  return formFields.length === 0 || !show ? (
    <InviteHeader requesterName={requesterName} message={message} />
  ) : (
    <>
      <h1 className="font-serif text-2xl font-medium">{t('header.addFields.title', { requesterName })}</h1>
      <div className="mt-2 flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-3 pb-4">
        <div className="flex flex-col gap-2 rounded-xl bg-secondary-50 p-2">
          {formFields
            .filter((f) => !isEmptyField(f))
            .map((f) => (
              <Field
                field={f}
                handleClick={handleFieldClick}
                active={selectedFields.map((x) => x.id).includes(f.id!)}
              />
            ))}
        </div>
        <div className="flex">
          <Button variant="secondary" onClick={handleAddFields}>
            {t('header.addFields.addFieldButton')}
          </Button>
          <Button className="underline" onClick={() => setShow(false)}>
            {t('header.addFields.dontAddButton')}
          </Button>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserStats } from '../../types/profile';
import { isMobile } from '../../utils/userAgentUtils';
import QuestionCircle from './QuestionCircle';

interface UserStatsDisplayProps {
  stats: UserStats | undefined;
}

export default function UserStatsDisplay({ stats }: UserStatsDisplayProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="relative flex w-fit">
      <div className="flex shrink-0 flex-col items-center border-r border-secondary-200 pr-4 lg:px-4">
        <p className="text-xs text-gray-500">{t('page.common.success.stats.connections')}</p>
        <p className="text-lg text-gray-500">{stats?.connections || 0}</p>
      </div>
      <div className="flex shrink-0 flex-col items-center border-r border-secondary-200 px-4">
        <p className="text-xs text-gray-500">{t('page.common.success.stats.communities')}</p>
        <p className="text-lg text-gray-500">{stats?.communities || 0}</p>
      </div>
      <div className="flex shrink-0 flex-col items-center pl-4">
        <p className="text-xs text-gray-500">{t('page.common.success.stats.actions')}</p>
        <p className="text-lg text-secondary">{stats?.notifications || 0}</p>
      </div>
      {!isMobile() && (
        <div className="-mt-[3px] ml-1 w-full">
          <QuestionCircle text={t('page.common.success.stats.explanation')} relative={false} position="right-0 top-6" />
        </div>
      )}
    </div>
  );
}

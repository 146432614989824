import { login } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import {
  BusinessInvite,
  BusinessVersion,
  CustomFieldResponses,
  CustomFieldType,
  InviteType,
  PersonalInvite,
} from '../types/invites';
import { SharedBusinessData } from '../types/misc';
import { ProfileField } from '../types/profile';
import {
  AcceptCommunityRequestBody,
  AcceptDataRequestBody,
  AcceptIntroductionBody,
  CreateCommunityConnectionRequest,
  CreateConnectionRequest,
  CreateMomentConnectionRequest,
  InstantRegisterRequest,
  Language,
  UpdateBusinessConnectionRequest,
  UpdateConnectionRequest,
  UseCrmUserInviteRequest,
  UseLocalContactInviteRequest,
  UsePersonalInviteRequest,
} from '../types/requests';
import {
  PicturePathResponse,
  RegisterResponse,
  SharedBusinessDataResponse,
  SharedDataResponse,
} from '../types/responses';
import httpService, { setRefreshToken, setToken } from './httpService';

export function instantRegister(
  email: string,
  alias: string,
  inviterAlias: string,
  inviteType: InviteType,
  invitationId: string,
  data: ProfileField[],
  lang: Language,
  customFieldResponses: CustomFieldResponses | undefined = undefined,
): Promise<() => void> {
  const [firstName, lastName] = alias.split('+');
  alias = `${firstName.trim()}+${lastName.trim()}`;
  const body: InstantRegisterRequest = {
    email,
    alias,
    inviterAlias,
    inviteType,
    invitationId,
    data,
    lang,
    customFieldResponses,
  };
  return httpService
    .post<RegisterResponse>('/v2/auth/instantRegister', body)
    .then(({ data: { accessToken, refreshToken } }): (() => void) => {
      setToken(accessToken);
      setRefreshToken(refreshToken);

      return () =>
        setTimeout(() => {
          store.dispatch(
            login({
              alias,
              email,
            }),
          );
        }, 10);
    });
}

export function createConnection(linkId: string, dataProvidedIds: string[]): Promise<string> {
  const body: CreateConnectionRequest = {
    linkId,
    dataProvidedIds,
  };
  return httpService.post<string>('/connection', body).then(({ data }): string => data);
}

export function createBusinessConnection(
  inviteId: string,
  args: SharedBusinessData,
  invite: BusinessInvite,
): Promise<string> {
  const cnf = invite.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME)?.customFieldId;

  return invite.version === BusinessVersion.MOMENTS
    ? createMomentConnection(inviteId, args.propertyIds, cnf ? args.customFieldResponses[cnf] : undefined)
    : createCommunityConnection(inviteId, args);
}

export function createCommunityConnection(inviteId: string, args: SharedBusinessData): Promise<string> {
  const body: CreateCommunityConnectionRequest = {
    inviteId,
    ...args,
  };
  return httpService.post<string>('/b2c-connection/accept/community', body).then(({ data }): string => data);
}

export function createMomentConnection(
  inviteId: string,
  propertyIds: string[],
  communicationName: string | undefined = undefined,
): Promise<string> {
  const body: CreateMomentConnectionRequest = {
    inviteId,
    propertyIds,
    communicationName,
  };
  return httpService.post<string>('/connection/moment', body).then(({ data }): string => data);
}

export function getSharedDataWithUser(userId: string): Promise<ProfileField[]> {
  return httpService
    .get<SharedDataResponse>(`/getAllSharedInfo/${userId}`)
    .then(({ data }): ProfileField[] => data.sharedDataWithTarget);
}

export function getSharedDataByUser(userId: string): Promise<ProfileField[]> {
  return httpService
    .get<SharedDataResponse>(`/getAllSharedInfo/${userId}`)
    .then(({ data }): ProfileField[] => data.sharedDataByTarget);
}

export function getProfilePictureOfUser(userId: string): Promise<string> {
  return httpService.get<PicturePathResponse>(`/users/${userId}/avatar`).then(({ data }): string => data.path);
}

export function getSharedDataWithBusiness(businessId: string): Promise<SharedBusinessData> {
  return httpService.get<SharedBusinessDataResponse>(`/b2c-connection/shared/${businessId}`).then(
    ({ data }): SharedBusinessData => ({
      ...data,
      propertyIds: data.propertyIds.map((pId: number): string => pId.toString()),
      dataForMembers: data.dataForMembers.map((d: number): string => d.toString()),
    }),
  );
}

export function updateConnection(targetId: string, dataIds: string[]): Promise<void> {
  const body: UpdateConnectionRequest = {
    targetId,
    dataIds,
  };
  return httpService.post<void>('/editSharedInfo', body).then();
}

interface UpdateBusinessConnectionOptions {
  updateMembers?: boolean;
  localContactToken?: string;
}

export function updateBusinessConnection(
  businessId: string,
  args: SharedBusinessData,
  options: UpdateBusinessConnectionOptions = {},
): Promise<void> {
  const body: UpdateBusinessConnectionRequest = {
    ...args,
    ...options,
  };

  return httpService.put<void>(`/b2c-connection/${businessId}`, body).then();
}

export function createLocalContactConnection(token: string, args: SharedBusinessData): Promise<string> {
  const body: UseLocalContactInviteRequest = {
    ...args,
    propertyIds: args.propertyIds.map((f) => +f),
  };

  return httpService
    .post<string>(`/business/local-contact/invite/accept/${token}`, body)
    .then(({ data }): string => data);
}

export function createCrmUserConnection(token: string, args: SharedBusinessData): Promise<void> {
  const body: UseCrmUserInviteRequest = {
    ...args,
    propertyIds: args.propertyIds.map((f) => +f),
  };

  return httpService.post<string>(`/crm/invite?token=${token}`, body).then(() => {});
}

export function createPersonalConnection(token: string, sharedProperties: number[]): Promise<PersonalInvite> {
  const body: UsePersonalInviteRequest = {
    accept: true,
    sharedProperties,
  };

  return httpService.post<PersonalInvite>(`/personal/${token}`, body).then(({ data }): PersonalInvite => data);
}

export function acceptDataRequest(
  id: { token?: string; dataRequestId?: number },
  dataProvidedIds: string[],
): Promise<void> {
  const { token, dataRequestId } = id;
  const body: AcceptDataRequestBody = {
    dataProvidedIds,
    dataRequestId,
  };
  return httpService.post(`/acceptRequest${token ? `/${token}` : ''}`, body).then((): void => {});
}

export function declineDataRequest(dataRequestId: number): Promise<void> {
  return httpService.delete(`/requestReceived?dataRequestId=${dataRequestId}`).then((): void => {});
}

export function respondToCommunityContactInvite(token: string, communityId: number, accepted: boolean): Promise<void> {
  return httpService
    .delete(`/communities/${communityId}/invite/contact?token=${token}&accepted=${accepted}`)
    .then(() => {});
}

export function declineIntroduction(introductionId: string): Promise<void> {
  return httpService.delete(`/deleteIntroduction?introductionId=${introductionId}`).then((): void => {});
}

export function acceptIntroduction(introductionId: string, dataProvidedIds: string[]): Promise<void> {
  const body: AcceptIntroductionBody = {
    introductionId,
    dataProvidedIds,
  };
  return httpService.post(`/acceptIntroduction`, body).then((): void => {});
}

export function acceptCommunityRequest(userId: number, communityId: number, sharedProps: number[]): Promise<void> {
  const body: AcceptCommunityRequestBody = {
    sharedProps,
  };
  return httpService.post(`/communities/${communityId}/members/${userId}/connect`, body).then((): void => {});
}

export function declineCommunityRequest(userId: number): Promise<void> {
  return httpService.delete(`communities/connection-requests/${userId}`).then(() => {});
}

/**
 * Respond to a connection invite and create a connection between
 * the user if the invite is accepted
 *
 * @param inviteId the id of the invite to respond to
 * @param accept whether we accept the invite or not
 * @param sharedProps the list of shared properties if the invite is accepted
 */
export function respondToConnectionInvite(inviteId: number, accept: boolean, sharedProps?: string[]) {
  return httpService.post('/connectionInvite', { inviteId, accept, sharedProps }).then((): void => {});
}

export function updateDataSharedWithCommunityMembers(businessId: number, dataForMembers: number[]): Promise<void> {
  return httpService
    .put(`/b2c-connection/${businessId}/members`, { dataForMembers, updateMembers: null })
    .then((): void => {});
}

import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const classes = {
  primary: 'bg-primary text-secondary-50',
  'primary-outline': 'border border-primary text-primary-900',
  secondary: 'bg-primary-300 text-primary-900',
  tertiary: 'bg-secondary-200 text-primary-900',
};

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'primary-outline';
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({ variant, className = '', children, ...props }: ButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        'flex h-fit w-fit items-center gap-2 whitespace-nowrap rounded-[20px] px-[16px] py-[8px] font-[600] text-primary transition-all hover:bg-opacity-80 hover:text-opacity-80 disabled:opacity-20 disabled:hover:bg-opacity-100',
        variant ? classes[variant] : undefined,
        className,
      )}>
      {children}
    </button>
  );
}

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HiUserGroup } from 'react-icons/hi';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import Button from '../../components/misc/Button';
import { BusinessInvite } from '../../types/invites';
import ApiImage from '../../components/misc/ApiImage';

interface CommunityInvitePageProps {
  invite: BusinessInvite;
  onSubmit: () => void;
}

export default function NoRequestedDataCommunityInvitePage({
  invite: { requesterAlias: name, profilePicture: picture, memberCount, description },
  onSubmit,
}: CommunityInvitePageProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ResponsePageWrapper>
      <ResponsePageWrapper.Header>
        <h1 className="font-serif text-2xl font-medium">
          {t('page.noRequestedDataCommunityInvitePage.header', { name })}
        </h1>
        <div className="flex">
          <img src="/images/misc/shield.svg" alt="Onboarding visual" className="mr-2 mt-[3px] h-5 w-5 object-cover" />
          <p className="h-fit w-fit text-sm font-light">{t('header.message', { name })}</p>
        </div>
        <div className="mt-2 flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-3 pb-4">
          <div
            className={classNames('flex w-full items-center gap-2', {
              'pl-5': !picture,
            })}>
            <ApiImage src={picture} alt={name} className="h-12 w-12 rounded-lg object-cover" />
            <div className="flex flex-col">
              {' '}
              <h2 className="font-serif text-lg font-medium">{name}</h2>
              {memberCount !== undefined && (
                <div className="flex items-center gap-1 text-sm">
                  <HiUserGroup className="h-5 w-5" />
                  {memberCount} {t('general.member', { count: memberCount || 2 })}
                </div>
              )}
            </div>
          </div>
          {description && <p className="-mt-1">{description}</p>}
        </div>
      </ResponsePageWrapper.Header>
      <div className="flex h-full flex-col gap-4">
        <div className="flex flex-col">
          <h2 className="text-lg font-medium">{t('page.noRequestedDataCommunityInvitePage.title', { name })}</h2>
          <p className="text-sm">{t('page.noRequestedDataCommunityInvitePage.subtitle', { name })}</p>
        </div>
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button variant="primary" className="px-[32px]" onClick={onSubmit}>
            {t('page.noRequestedDataCommunityInvitePage.submit', { name })}
          </Button>
        </div>
      </div>
    </ResponsePageWrapper>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiMapPin } from 'react-icons/fi';
import { FaRegClock, FaUsers } from 'react-icons/fa';
import i18n from 'i18next';
import eventUtils, { formatEventTime, formatFriendlyEventDate, getEventAttendees } from '../../utils/eventUtils';
import IconBadge from '../icons/IconBadge';
import { getFormattedStartEndDate } from '../../utils/dateUtils';
import { Event } from '../../types/Events';
import InfoBox from '../misc/InfoBox';
import ApiImage from '../misc/ApiImage';

interface Props {
  event: Event;
  children?: React.ReactNode;
  style?: string;
}

function EventOverview({ event, style = '', children }: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    title,
    description,
    startTime,
    endTime,
    location,
    locationUrl,
    maximumAttendees,
    organizer,
    deadline,
    isRecurring,
  } = event;

  const numberOfAttendees = getEventAttendees(event);
  const deadlineIsPassed = deadline ? new Date() > new Date(deadline) : false;
  const maximumAttendeesReached = maximumAttendees ? numberOfAttendees >= maximumAttendees : false;

  return (
    <div className={`flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-4 ${style}`}>
      <div className="flex gap-4">
        {event.picture && <ApiImage src={event.picture} alt={title} className="w-24 rounded-xl object-cover" />}
        <div className="flex flex-col">
          <h1 className="font-serif text-[28px] leading-[28px]">{title}</h1>
          <h2 className="text-sm">{t('page.events.infoScreen.organizedBy', { name: organizer })}</h2>
        </div>
      </div>
      <div className="w-full border-b border-primary-300" />
      <div className="flex w-full flex-col gap-2">
        <h2 className="font-serif text-[18px] leading-[24px]">{t('general.information')}</h2>

        {!event.isDatePicker && (
          <>
            <div className="flex items-center gap-2">
              <IconBadge icon={FiCalendar} className="rounded-[6px]" />
              <p className="text-sm">{getFormattedStartEndDate(startTime, endTime)}</p>
            </div>
            <div className="flex items-center gap-2">
              <IconBadge icon={FaRegClock} className="rounded-[6p x]" />
              <p className="text-sm">{eventUtils.formatDate(event, 'time')}</p>
            </div>
          </>
        )}

        {maximumAttendees && (
          <div className="flex items-center gap-2">
            <IconBadge icon={FaUsers} className="rounded-[6px]" />
            <p className="text-sm">
              {t('page.events.infoScreen.maximumAttendees', { attendees: numberOfAttendees, max: maximumAttendees })}
            </p>
          </div>
        )}
        {deadline && (
          <div className="flex items-center gap-2">
            <IconBadge icon={FiCalendar} className="rounded-[6px]" />
            <p className="text-sm">
              {t('page.events.infoScreen.deadline', {
                date: `${formatFriendlyEventDate(new Date(deadline))} ${formatEventTime(new Date(deadline))}`,
              })}
            </p>
          </div>
        )}
        {isRecurring && (
          <div className="flex items-center gap-2">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">
              {t('page.events.infoScreen.recurring', {
                frequency: i18n.t(`page.events.infoScreen.frequencies.${event.frequency.toLowerCase()}`),
              })}
            </p>
          </div>
        )}
        {location && (
          <a href={locationUrl} target="_blank" rel="noreferrer" className="flex items-center gap-2 hover:underline">
            <IconBadge icon={FiMapPin} className="rounded-[6px]" />
            <p className="text-sm">{location}</p>
          </a>
        )}
        {description && (
          <div className="flex w-full flex-col">
            <h2 className="font-serif text-[18px] leading-[24px]">{t('general.description')}</h2>
            <p className="text-sm">{description}</p>
          </div>
        )}
      </div>
      {deadlineIsPassed && <InfoBox label={t('page.events.infoScreen.deadlinePassed')} />}
      {maximumAttendeesReached && <InfoBox label={t('page.events.infoScreen.maximumAttendeesReached')} />}
      {children} {/* Children like attendance icons, custom fields form or date picker options */}
    </div>
  );
}

export default EventOverview;

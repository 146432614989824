import { PersonalLinkData } from '../types/invites';
import httpService from './httpService';

export function fetchPersonalLinkData(linkId: string): Promise<PersonalLinkData> {
  return httpService.get<PersonalLinkData>(`/personalLink/${linkId}`).then(({ data }): PersonalLinkData => data);
}

/**
 * Use someone's personal link to invite them to
 * connect in the application
 *
 * @param linkId the id of the link to use
 * @param sharedProps the props to share with the invite
 */
export async function usePersonalLink(linkId: string, sharedProps: number[]): Promise<void> {
  await httpService.post<void>(`/personalLink/${linkId}`, { sharedProps });
}

/**
 * Accept an invite made through your personal link
 *
 * @param inviteId the id of the invite
 * @param sharedProps the props to share when accepting the invite
 */
export async function acceptConnectionInvite(inviteId: string, sharedProps: number[]): Promise<void> {
  await httpService.post<void>(`/connectionInvite`, { inviteId, sharedProps });
}

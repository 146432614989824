import React, { useRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { ProfileDataType, ProfileField } from '../../types/profile';
import { profileFieldToString } from '../../utils/stringUtils';
import { profileDataTypeIcons, socialTypeIcons } from '../../utils/iconUtils';
import { authenticationService } from '../../services';
import useAuth from '../../hooks/useAuth';
import { isEmptyField } from '../../utils/validationUtils';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';

interface ContactConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  info: { alias: string; receivedData: ProfileField[] } | undefined;
  inviterName: string | undefined;
}

export default function ContactConfirmationModal({
  open,
  setOpen,
  info,
  inviterName,
}: ContactConfirmationModalProps): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();
  const fields = info?.receivedData.filter((f) => !isEmptyField(f)) || [];

  const focusRef = useRef(null);

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="h-a z-50 mx-6 flex w-fit min-w-[450px] max-w-[600px] flex-col gap-4 rounded-3xl bg-secondary-50 p-8 pb-4 pt-5">
      <input className="invisible hidden" ref={focusRef} />
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl font-medium">{t('modal.localContactConfirmation.title')}</h2>
        <p className="text-sm">
          {t('modal.localContactConfirmation.description', {
            authName: auth?.alias.replace('+', ' '),
            authEmail: auth?.email,
            inviterName,
          })}
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="flex items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
            <AiOutlineUser className="h-5 w-5" strokeWidth="2" />
          </div>
          <p className="my-auto">{info?.alias.replace('+', ' ') || ''}</p>
        </div>
        {fields.map((f) => (
          <div className="flex gap-2" key={f.dataType}>
            <div className="flex items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
              {f.dataType === ProfileDataType.SOCIAL && f.social
                ? socialTypeIcons[f.social?.socialType]
                : profileDataTypeIcons[f.dataType]}
            </div>
            <p className="my-auto">
              {f.dataType === ProfileDataType.SOCIAL ? f.social?.link : profileFieldToString(f)}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-2 flex justify-end gap-2">
        <Button
          className="underline"
          onClick={() => {
            setOpen(false);
            authenticationService.logOut();
          }}>
          {t('general.logOut')}
        </Button>
        <Button variant="primary" className="px-[32px]" onClick={() => setOpen(false)}>
          {t('general.continue')}
        </Button>
      </div>
    </ModalWrapper>
  );
}

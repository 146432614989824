import _ from 'lodash';
import {
  AddressProperty,
  EmailProperty,
  PhoneNumberProperty,
  ProfileDataType,
  ProfileField,
  SocialType,
} from './profile';

export type Invite = {
  dataProvidedTypes: ProfileDataType[];
  dataRequest: ProfileDataType[];
  expiryDate: string;
  id: string;
  message: string;
  pdata: {
    label: string;
    dataType: string;
  }[];
  profilePicture: string;
  provider: {
    profile: {
      alias: string;
    };
  };
  providerId: string;
  theme: string;
};

export const BusinessInvitePropertyType = _.omit(
  { ...ProfileDataType, ...SocialType },
  ProfileDataType.MOMENT,
  ProfileDataType.SOCIAL,
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BusinessInvitePropertyType = Omit<ProfileDataType, 'MOMENT' | 'SOCIAL'> | SocialType;

export interface BusinessInvite {
  requesterAlias: string;
  requesterId: string;
  message: string;
  profilePicture: string;
  mandatoryFields: BusinessInvitePropertyType[];
  optionalFields: BusinessInvitePropertyType[];
  nonRequestedFields: BusinessInvitePropertyType[];
  customFields: CustomField[];
  acceptAutomatically: boolean;
  version: BusinessVersion;
  connectedMembers?: boolean;
  shareWithAdmins: boolean;
  memberCount?: number;
  description: string;
}

export enum BusinessVersion {
  MOMENTS = 'MOMENTS',
  COMMUNITY = 'COMMUNITY',
}

/**
 * Information about a connection invite
 */
export interface ConnectionInvite {
  /**
   * The id of the user who sent the invite
   */
  inviterId: number;

  /**
   * The first name of the user who sent the invite
   */
  inviterFirstName: string;

  /**
   * The primary email of the user who sent the invite
   */
  inviterEmail: string;
}

export enum InviteType {
  BUSINESS = 'BUSINESS',
  MOMENTS = 'MOMENTS',
  USER = 'USER',
  COMMUNITY = 'COMMUNITY',
  LOCAL_CONTACT = 'LOCAL_CONTACT',
  PERSONAL = 'PERSONAL',
  CONNECTION = 'CONNECTION',
  DATA_REQUEST = 'DATA_REQUEST',
  COMMUNITY_CONTACT_INVITE = 'COMMUNITY_CONTACT_INVITE',
  INTRODUCTION = 'INTRODUCTION',
  COMMUNITY_REQUEST = 'COMMUNITY_REQUEST',
  EMAIL_PREFERENCE = 'EMAIL_PREFERENCE',
  EVENT = 'EVENT',
  DATE_PICKER = 'DATE_PICKER',
  CRM_USER_INVITE = 'CRM_USER_INVITE',
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  COMMUNICATION_NAME = 'COMMUNICATION_NAME',
}

export type CustomTextField = {
  customFieldId: number;
  type: CustomFieldType.TEXT;
  label: string;
  mandatory: boolean;
};

export type CustomMultipleChoiceField = {
  customFieldId: number;
  type: CustomFieldType.MULTIPLE_CHOICE;
  label: string;
  options: string[];
  mandatory: boolean;
};

export type CommunicationName = {
  customFieldId: number;
  type: CustomFieldType.COMMUNICATION_NAME;
  mandatory: boolean;
};

export type CustomLabelField = CustomTextField | CustomMultipleChoiceField;

export type CustomField = CustomLabelField | CommunicationName | EventCustomField;

export type EventCustomField = {
  customFieldId: number;
  label: string;
  options?: string[];
  mandatory: boolean;
  type: CustomFieldType.MULTIPLE_CHOICE | CustomFieldType.TEXT;
  responses?: {
    userId: number;
    user: {
      id: number;
      firstName: string;
      lastName: string;
      picture: string;
    };
    customFieldId: number;
    response: string;
  }[];
};

/**
 * Response for custom fields
 */
export type CustomFieldResponses = {
  [customFieldId: number]: string;
};

export interface CommunityInvite {
  /**
   * The ID of the community.
   */
  communityId: number;

  /**
   * The name of the community.
   */
  communityName: string;

  /**
   * The picture of the community.
   * Optional, as the community might not have a picture.
   */
  communityPicture?: string;

  /**
   * THe description of the community.
   * Optional, as the community might not have a description.
   */
  communityDescription?: string;
  /**
   * Size of the community.
   */
  communitySize: number;

  /**
   * The ID of the user who sent the invite link.
   */
  inviterId: number;

  /**
   * The first name of the inviter.
   */
  inviterFirstName: string;

  /**
   * The last name of the inviter.
   */
  inviterLastName: string;
}

export type LocalContactInvite = BusinessInvite & {
  hasAccount: boolean;
  data: LocalContactInfo;
};

export interface LocalContactInfo {
  alias: string;
  receivedData: ProfileField[];
  customFieldResponses: CustomFieldResponses;
}

export interface PersonalInvite {
  inviter: {
    userId: number;
    firstName: string;
    lastName: string;
    picture?: string;
  };
  data: {
    nativeContactId?: string;
    firstName?: string;
    lastName?: string;
    businessName?: string;
    birthdate?: {
      day: number;
      month: number;
      year: number;
    };
    phoneNumbers: PhoneNumberProperty[];
    addresses: AddressProperty[];
    emails: EmailProperty[];
    requestedDataTypes: ProfileDataType[];
    dataToShare: number[];
  };
}

/**
 * The data saved in a personal link in the database
 */
export interface PersonalLinkData {
  /**
   * The first name of the user who sent the link
   */
  firstName: string;

  /**
   * The last name of the user who sent the link
   */
  lastName: string;

  /**
   * The picture of the user who sent the link
   */
  picture?: string;

  /**
   * The id of the user who sent the link
   */
  userId: number;
}

export interface DataRequest {
  requesterAlias: string;
  senderId: string;
  requestedProps: ProfileDataType[];
  dataRequestId: number;
}

/**
 * Information about data requests required by the
 * front-end
 */
export interface Introduction {
  /**
   * The id of the introduction
   */
  id: string;

  /**
   * The alias of the user that created
   * the introduction
   */
  introducerAlias: string;

  /**
   * The id of the person creating the introduction
   */
  introducerId: number;

  /**
   * The picture of the introducr
   */
  introducerPicture: string;

  /**
   * The alias of the user being introduced
   * other than the current user
   */
  introducedAlias: string;

  /**
   * The id of the person being introduced
   */
  introducedId: number;

  /**
   * The message being sent to the front-end
   * kept for legacy purposes
   */
  message: string;
}

export interface CommunityRequest {
  /**
   * The id of the user
   */
  userId: number;
  /**
   * Alias of the user
   */
  alias: string;
  /**
   * The community id
   */
  communityId: number;
  /**
   * Name of the community
   */
  communityName: string;
}

export type CrmUserInvite = BusinessInvite & {
  alias: string;
  receivedData: ProfileField[];
};

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3500';

// Color pallette
export const COLOR_PRIMARY = '#0A1B89';
export const COLOR_PRIMARY_300 = '#92A2EC';
export const COLOR_PRIMARY_900 = '#081647';
export const COLOR_SECONDARY = '#F19E7A';
export const COLOR_SECONDARY_50 = '#FCEEEB';
export const COLOR_SECONDARY_200 = '#EDD5C9';
export const COLOR_ERROR = '#BB0000';

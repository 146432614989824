import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalWrapper from '../layouts/ModalWrapper';
import { MagicGenerationRequest } from '../../types/requests';
import { authenticationService } from '../../services';
import Button from '../misc/Button';

interface AccountExistsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  request?: MagicGenerationRequest;
}

export default function AccountExistsModal({ open, setOpen, request }: AccountExistsModalProps): JSX.Element {
  const { t } = useTranslation();

  const { email, invitationId, communityInvitationId, inviteType, personalLinkId, eventId } = request || {};

  useEffect(() => {
    if (open) sendLink();
  }, [open]);

  const sendLink = async () => {
    if (!email) return;
    await authenticationService
      .generateMagicLink(email, invitationId, communityInvitationId, inviteType, personalLinkId, eventId)
      .then(() => toast.success(t('toast.success.login')))
      .catch(() => {});
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="mx-6 flex w-fit min-w-[450px] max-w-[600px] flex-col gap-4 rounded-3xl bg-secondary-50 p-8 pb-4 pt-5">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-medium">{t('modal.accountExists.title')}</h2>
        <div className="flex flex-row gap-6">
          <img alt="Moments" src={`${process.env.PUBLIC_URL}/images/figures/heart.svg`} className="h-40" />
          <p className="w-60 text-sm">{t('modal.accountExists.description')}</p>
        </div>
        <Button variant="primary" className="mt-2 w-full justify-center" onClick={sendLink}>
          {t('modal.accountExists.button')}
        </Button>
      </div>
    </ModalWrapper>
  );
}

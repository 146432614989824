export enum UserAccountCreationChannel {
  PERSONAL_INVITE,
  OPEN_INVITE_FROM_APP,
  PUBLIC_INVITE,
  GROUP_INVITE,
  DEFAULT,
  EVENT_INVITE,
  DATE_PICKER_INVITE,
  LOCAL_CONTACT_INVITE,
  CRM_USER_INVITE,
}

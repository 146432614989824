import { format } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import { FaCheck, FaQuestion } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { DateOption, EventParticipationAnswer, Event } from '../types/Events';
import { capitalizeFirstLetter, capitalizeWords } from './stringUtils';
import { store } from '../redux/store';
import { Language } from '../types/requests';
import dateUtils from './dateUtils';
import I18n from '../locales/i18n';
import { EventCustomField } from '../types/invites';

const ANSWER_WEIGHTS = {
  [EventParticipationAnswer.PENDING]: 0,
  [EventParticipationAnswer.NO]: 0,
  [EventParticipationAnswer.MAYBE]: 1,
  [EventParticipationAnswer.YES]: 2,
};

export function scoreDateOption(dateOption: DateOption): number {
  return dateOption.answers.map((a) => ANSWER_WEIGHTS[a.answer]).reduce((a, b) => a + b, 0);
}

export function calculatePercentage(dateOption: DateOption): number {
  return (
    (scoreDateOption(dateOption) /
      dateOption.answers.filter((a) => a.answer !== EventParticipationAnswer.PENDING).length /
      ANSWER_WEIGHTS[EventParticipationAnswer.YES]) *
    100
  );
}

export function sortDateOptions(options: DateOption[], type: 'score' | 'time' = 'score'): DateOption[] {
  const newOptions = [...options];
  return newOptions.sort((a, b) =>
    type === 'score'
      ? scoreDateOption(b) - scoreDateOption(a)
      : new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
  );
}

export function sortEvents(events: Event[]): Event[] {
  const newEvents = [...events];
  return newEvents.sort((a, b) => {
    const aDate = a.dateOptions?.length ? a.dateOptions[0].startTime : a.startTime;
    const bDate = b.dateOptions?.length ? b.dateOptions[0].startTime : b.startTime;
    return new Date(aDate).getTime() - new Date(bDate).getTime();
  });
}

function formatDate<T extends { startTime: Date; endTime?: Date }>(
  { startTime, endTime }: T,
  type: 'date' | 'time' = 'date',
  language = store.getState().language,
): string {
  const startDate = new Date(startTime);
  const endDate = endTime ? new Date(endTime) : undefined;

  const locale = language === Language.EN ? enUS : nl;
  const formatter = type === 'date' ? 'EEEEEE. d LLLL' : 'HH:mm';
  const dateDiff = endDate ? dateUtils.calculateDayDifference(startDate, endDate) : undefined;

  return `${capitalizeWords(
    format(startTime, startDate.getMonth() === endDate?.getMonth() ? formatter.replace(' LLLL', '') : formatter, {
      locale,
    }),
  )}${endTime ? ` - ${capitalizeWords(format(endTime, formatter, { locale }))}` : ''} ${
    dateDiff && dateDiff > 0 && type === 'time' ? `(+${+dateDiff})` : ''
  }`;
}

export const getEventAttendees = (event: Event): number => {
  return event.participants.filter((p) => p.answer === EventParticipationAnswer.YES).length;
};

export const getEventAttendanceAnswers = (i18n: typeof I18n): [EventParticipationAnswer, string, IconType][] => [
  [EventParticipationAnswer.YES, i18n.t('page.events.infoScreen.attendance.yes'), FaCheck],
  [EventParticipationAnswer.MAYBE, i18n.t('page.events.infoScreen.attendance.maybe'), FaQuestion],
  [EventParticipationAnswer.NO, i18n.t('page.events.infoScreen.attendance.no'), FaXmark],
];

export function getFriendlyDateObject(
  date: Date,
  locale: string,
  long: boolean = true,
): {
  dayOfWeek: string;
  day: string;
  month: string;
  year: string;
} {
  const dayOfWeek = new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  const day = new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat(locale, { month: long ? 'long' : 'short' }).format(date);
  const year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(date);

  return {
    dayOfWeek: capitalizeFirstLetter(dayOfWeek),
    day,
    month,
    year,
  };
}

function isCurrentYear(date: Date | number): boolean {
  const year = typeof date === 'number' ? date : date.getFullYear();
  const currentYear = new Date().getFullYear();
  return currentYear === year;
}

export function formatFriendlyDate(date: Date, locale: string, long: boolean = true): string {
  const { dayOfWeek, day, month, year } = getFriendlyDateObject(date, locale, long);

  if (isCurrentYear(date)) return `${dayOfWeek} ${day} ${month}`;

  return `${dayOfWeek} ${day} ${month} ${year}`;
}

export function formatFriendlyEventDate(startDate: Date, endDate?: Date, locale = 'nl-NL'): string {
  if (!endDate) {
    return formatFriendlyDate(startDate, locale);
  }

  const start = getFriendlyDateObject(startDate, locale);
  const end = getFriendlyDateObject(endDate, locale);

  if (start.year !== end.year) {
    return `${start.dayOfWeek} ${start.day} ${start.month} ${start.year} - ${end.dayOfWeek} ${end.day} ${end.month} ${end.year}`.trim();
  }

  if (start.day === end.day && start.month === end.month) {
    if (isCurrentYear(+start.year)) return `${start.dayOfWeek} ${start.day} ${start.month}`.trim();

    return `${start.dayOfWeek} ${start.day} ${start.month} ${start.year}`.trim();
  }

  if (start.month === end.month) {
    if (isCurrentYear(+start.year)) {
      return `${start.dayOfWeek} ${start.day} - ${end.dayOfWeek} ${end.day} ${end.month}`.trim();
    }

    return `${start.dayOfWeek} ${start.day} ${start.month} - ${end.dayOfWeek} ${end.day} ${end.month} ${end.year}`.trim();
  }

  if (isCurrentYear(+start.year)) {
    return `${start.dayOfWeek} ${start.day} ${start.month} - ${end.dayOfWeek} ${end.day} ${end.month}`.trim();
  }

  return `${start.dayOfWeek} ${start.day} ${start.month} - ${end.dayOfWeek} ${end.day} ${end.month} ${end.year}`.trim();
}

export function formatTime(date: Date): string {
  if (date === null) return '';
  const hours = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
  const minutes = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
  return `${hours}:${minutes}`;
}

export function formatEventDate(startDate: Date, endDate?: Date): string {
  if (startDate === null) return '';

  startDate = new Date(startDate);

  const getFormattedDate = (date: Date): string => `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

  const startTime = getFormattedDate(startDate);
  let endTime = '';

  if (endDate) {
    endDate = new Date(endDate);

    if (startDate.toDateString() !== endDate.toDateString()) {
      endTime = getFormattedDate(endDate);
    }
  }

  if (!endTime) return startTime;

  return `${startTime} - ${endTime}`;
}

export function formatEventTime(startDate: Date, endDate?: Date): string {
  if (!startDate) return '';

  const startDateObj = new Date(startDate);
  const endDateObj = endDate ? new Date(endDate) : null;

  const getFormattedTime = (date: Date): string => {
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${hours}:${minutes}`;
  };

  const startTime = getFormattedTime(startDateObj);
  let endTime = '';

  if (endDateObj) {
    const endTimeFormatted = getFormattedTime(endDateObj);

    if (startDateObj.toDateString() === endDateObj.toDateString()) {
      endTime = endTimeFormatted; // Same day
    } else {
      endTime = `${endTimeFormatted} (+${dateUtils.calculateDayDifference(startDateObj, endDateObj)})`; // Different days
    }
  }

  if (!endTime) return startTime;

  return `${startTime} - ${endTime}`;
}

export const getPollResponses = (userId: number, customFields: EventCustomField[] = []) =>
  Object.fromEntries(
    customFields.map((field) => [
      field.customFieldId,
      field.responses!.find((r) => r.userId === userId)?.response ?? '',
    ]),
  );

export const userHasGivenAvailability = (event: Event, userId?: number) =>
  event?.dateOptions.every((dateOption) =>
    dateOption.answers
      .filter((answer) => answer.id === userId)
      .every((answer) => answer.answer !== EventParticipationAnswer.PENDING),
  );

export default {
  ANSWER_WEIGHTS,
  scoreDateOption,
  calculatePercentage,
  sortDateOptions,
  formatDate,
  sortEvents,
  getEventAttendees,
  getEventAttendanceAnswers,
};

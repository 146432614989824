import React from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import Icon from './Icon';

interface IconBadgeProps {
  icon: IconType;
  className?: string;
  iconClassName?: string;
  size?: 'small' | 'medium' | 'large';
}

const sizes = {
  style: {
    small: 'size-8',
    medium: 'size-10',
    large: 'size-12',
  },
  iconStyle: {
    small: 'size-4',
    medium: 'size-5',
    large: 'size-6',
  },
};

export default function IconBadge({ icon, className, iconClassName, size = 'medium' }: IconBadgeProps) {
  return (
    <div
      className={twMerge(
        'flex flex-shrink-0 rounded-[6px] bg-secondary text-secondary-50',
        sizes.style[size],
        className,
      )}>
      <Icon
        icon={icon}
        className={twMerge('mx-auto my-auto inline-block size-5', sizes.iconStyle[size], iconClassName)}
        aria-hidden
      />
    </div>
  );
}

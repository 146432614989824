import React from 'react';
import { API_URL } from '../../constants';

interface ApiImageProps {
  src: string | undefined;
  alt?: string;
  className?: string;
}

export default function ApiImage({ src, alt, className }: ApiImageProps): JSX.Element {
  if (!src) return <></>;

  src = src?.replace('./', '');
  const uri = src?.startsWith('uploads') ? `${API_URL}/${src.replace('\\', '/')}` : src;

  return <img src={uri} alt={alt} className={className} />;
}

import React from 'react';
import { HiUserGroup } from 'react-icons/hi';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CommunityInvite } from '../../types/invites';
import ApiImage from '../misc/ApiImage';

interface CommunityInviteHeaderProps {
  invite: CommunityInvite;
  submessage?: string;
}
export default function CommunityInviteHeader({ invite, submessage = '' }: CommunityInviteHeaderProps): JSX.Element {
  const { t } = useTranslation();

  const { inviterFirstName, communityName, communityPicture, communityDescription, communitySize } = invite || {};

  return (
    <>
      <h1 className="font-serif text-2xl font-medium">
        {t('header.communityInvite.title', { inviterName: inviterFirstName, communityName })}
      </h1>
      <div className="flex">
        <img src="/images/misc/shield.svg" alt="Onboarding visual" className="mr-2 mt-[3px] h-5 w-5 object-cover" />
        <p className="h-fit w-fit text-sm font-light">
          {submessage || t('header.message', { name: inviterFirstName })}
        </p>
      </div>
      <div className="mt-2 flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-3 pb-4">
        <div className={classNames('flex w-full items-center gap-2', { 'pl-5': !communityPicture })}>
          {communityPicture && (
            <ApiImage src={communityPicture} alt={communityName} className="h-12 w-12 rounded-lg object-cover" />
          )}
          <div className="flex flex-col">
            {' '}
            <h2 className="font-serif text-lg font-medium">{communityName}</h2>
            <div className="flex items-center gap-1 text-sm">
              <HiUserGroup className="h-5 w-5" />
              {communitySize} {t('general.member', { count: communitySize || 2 })}
            </div>
          </div>
        </div>
        <p className="-mt-1">{communityDescription}</p>
      </div>
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonPageWrapper from '../../components/layouts/CommonPageWrapper';

export default function NotFoundPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <CommonPageWrapper>
      <CommonPageWrapper.Header>
        <div className="relative flex h-full w-full items-center justify-center gap-2.5 rounded-[40px] bg-secondary p-5">
          <img
            alt="Not found"
            src={`${process.env.PUBLIC_URL}/images/figures/mess_sad.svg`}
            className="mx-auto h-full w-[80%]"
          />
        </div>
      </CommonPageWrapper.Header>
      <CommonPageWrapper.Title>{t('page.common.notFound.title')}</CommonPageWrapper.Title>
    </CommonPageWrapper>
  );
}

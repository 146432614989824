import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FaPlus } from 'react-icons/fa6';
import { AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalWrapper from '../layouts/ModalWrapper';
import { RootState } from '../../types/redux';
import ImageInput from '../forms/ImageInput';
import { profileService } from '../../services';
import Button from '../misc/Button';
import ProfileFieldForm from '../forms/profile/ProfileFieldForm';
import { ProfileDataType, ProfileField, SocialType } from '../../types/profile';
import { createEmptyField, getProfileFieldCategories } from '../../utils/formatUtils';
import { sortProfileFields } from '../../utils/sortUtils';
import { isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import NameInput from '../forms/profile/NameInput';
import useAuth from '../../hooks/useAuth';

interface ProfileDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ProfileDataModal({ open, setOpen }: ProfileDataModalProps): JSX.Element {
  const { profileFields: oldFields, profilePicture: oldProfilePicture } = useSelector(
    (state: RootState) => state.application,
  );
  const { t } = useTranslation();
  const { alias: oldAlias } = useAuth() ?? {};
  const [profileFields, setProfileFields] = useState(sortProfileFields([...(oldFields ?? [])]));
  const [profilePicture, setProfilePicture] = useState(oldProfilePicture);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [alias, setAlias] = useState<string>(oldAlias ?? '');
  const [imageAction, setImageAction] = useState<'UPLOAD' | 'DELETE'>();
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    const diff =
      oldFields?.filter((f) =>
        _.isEqual(
          f,
          profileFields?.findIndex((pf) => pf.id === f.id),
        ),
      ) ?? [];
    setProfileFields(profileFields.map((f) => diff.find((d) => d.id === f.id) ?? f));
  }, [JSON.stringify(oldFields)]);

  useEffect(() => {
    setProfileFields(sortProfileFields([...(oldFields ?? [])]));
    setProfilePicture(oldProfilePicture);
    setImageAction(undefined);
    setValidate(false);
    setDeleteIds([]);
    setAlias(oldAlias ?? '');
  }, [open]);

  const canAdd = (type: ProfileDataType) => {
    switch (type) {
      case ProfileDataType.BUSINESSNAME:
      case ProfileDataType.BIRTHDATE:
        return profileFields.filter((f) => f.dataType === type).length === 0;
      case ProfileDataType.SOCIAL:
        return profileFields.map((f) => f.social?.socialType).filter(Boolean).length < Object.values(SocialType).length;
      case ProfileDataType.MOMENT:
        return false;
      default:
        return true;
    }
  };

  const handleSave = async () => {
    setValidate(true);
    const [firstName, lastName] = alias.split('+');
    if (!firstName || !lastName) return;
    if (!isValidProfileFields(profileFields) || profileFields.some((f) => isEmptyField(f))) return;
    if (imageAction === 'UPLOAD' && profilePicture)
      await profileService.setProfilePicture(await fetch(profilePicture).then((res) => res.blob() as Promise<File>));
    if (imageAction === 'DELETE') await profileService.deleteProfilePicture();
    setImageAction(undefined);
    await Promise.all([
      ...profileFields
        .filter(
          (f) =>
            f.id &&
            !_.isEqual(
              f,
              oldFields?.find((of) => of.id === f.id),
            ),
        )
        .map((f) => profileService.updateField(f)),
      profileService.addFields(profileFields.filter((f) => !f.id)),
      profileService.deleteFields(deleteIds),
      alias !== oldAlias && profileService.changeName(alias),
    ]);

    await Promise.all([]);
    setOpen(false);
  };

  const setFieldByTypeIndex = (field: ProfileField | undefined, type: ProfileDataType, typeIndex: number) => {
    const index = profileFields.findIndex((f) => f.dataType === type) + typeIndex;
    const newFields = [...profileFields];
    if (field) newFields[index] = field;
    if (!field) {
      if (newFields[index].id) setDeleteIds(_.uniq([...deleteIds, newFields[index].id!]));
      newFields.splice(index, 1);
    }
    setProfileFields(newFields);
  };

  const addField = (type: ProfileDataType) => {
    const newFields = [...profileFields];
    newFields.push(createEmptyField(type));
    setProfileFields(sortProfileFields(newFields));
  };

  const categories = _.omit(getProfileFieldCategories(profileFields), 'moment');

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="z-50 mx-6 my-8 flex w-full flex-col gap-4 rounded-[20px] bg-secondary-50 p-4 sm:max-w-[700px] md:p-8">
      <div className="flex flex-col gap-4 md:flex-row md:items-end">
        <ImageInput
          className="h-[120px] w-[120px] flex-shrink-0"
          iconClassName="h-6 w-6"
          image={profilePicture}
          setImage={setProfilePicture}
          setImageAction={setImageAction}
        />

        <NameInput alias={alias} setAlias={setAlias} />
      </div>
      {Object.entries(categories).map(([c, fs]) => (
        <div className="flex w-full flex-col">
          <h1 className="mb-1 text-xl font-bold">{t(`dataCategory.${c}`)}</h1>
          {fs.length > 0 && (
            <div className="mb-2 flex w-full flex-col gap-4">
              {fs.map((f, fi) => (
                <div className="flex w-full gap-2">
                  <ProfileFieldForm
                    key={f.id}
                    field={f}
                    excludeSocials={profileFields.map((pf) => pf.social?.socialType).filter(Boolean) as SocialType[]}
                    canBeEmpty={false}
                    validate={validate}
                    setField={(field) => setFieldByTypeIndex(field, f.dataType, fi)}
                  />

                  <AiOutlinePlus
                    className="mt-[41px] h-6 w-6 flex-shrink-0 rotate-45 cursor-pointer stroke-2"
                    onClick={() => setFieldByTypeIndex(undefined, f.dataType, fi)}
                  />
                </div>
              ))}
            </div>
          )}
          {canAdd(c.toUpperCase() as ProfileDataType) && (
            <Button
              variant="tertiary"
              onClick={() => addField(c.toLocaleUpperCase() as ProfileDataType)}
              className="mt-4">
              <FaPlus className="mr-2 inline-block h-5 w-5 stroke-2" />
              {t('general.add')}
            </Button>
          )}
        </div>
      ))}
      <div className="ml-auto flex gap-2">
        <Button className="underline" onClick={() => setOpen(false)}>
          {t('general.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useDeleteParams from '../../hooks/useDeleteParams';
import { eventService } from '../../services';
import { DateOptionAnswer, Event, EventParticipationAnswer } from '../../types/Events';
import { CustomFieldResponses } from '../../types/invites';
import { getUserId } from '../../services/httpService';
import { getPollResponses, userHasGivenAvailability } from '../../utils/eventUtils';
import { formatCustomFieldResponsesFromCustomFields } from '../../utils/formatUtils';
import { SuccessPageState } from '../../types/navigation';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import EventOverview from '../../components/overviews/EventOverview';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import Button from '../../components/misc/Button';
import EventSelectDateOption from '../../components/forms/events/EventSelectDateOption';

function AuthenticatedEventRespondPage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useDeleteParams('link');

  const linkId = params.get('id');

  const [event, setEvent] = useState<Event>();
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);
  const [hasGivenAvailability, setHasGivenAvailability] = useState<boolean>(false);
  const [dateOptionsUpdate, setDateOptionsUpdate] = useState<
    Record<number, { answer: EventParticipationAnswer; description?: string }>
  >({});

  useEffect(() => {
    eventService
      .getEventInviteWithToken(linkId as string)
      .then((e) => setEvent(e))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  useEffect(() => {
    try {
      const userId = getUserId();
      if (!userId || !event) return;

      if (!event.isDatePicker) {
        navigate(`/event/invite?id=${event.id}`);
      }

      const responses = getPollResponses(userId, event!.customFields);
      setCustomFieldResponses(formatCustomFieldResponsesFromCustomFields(event!.customFields, responses));
      setHasGivenAvailability(userHasGivenAvailability(event, userId));
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  const validate = () => {
    if (!hasGivenAvailability && Object.keys(dateOptionsUpdate).length !== event!.dateOptions.length) {
      toast.error(t('page.events.invite.datePickers.missingOptions'));
      return false;
    }

    if (event!.customFields.length > 0) {
      return event!.customFields.every((field) => {
        if (field.mandatory && !customFieldResponses[field.customFieldId]) {
          toast.error(t('toast.error.field.mandatoryStar'));
          return false;
        }
        return true;
      });
    }

    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate() || !event?.id) return;

    const dateOptions = Object.entries(dateOptionsUpdate).map(([dateOptionId, { answer, description }]) => ({
      dateOptionId: parseInt(dateOptionId, 10),
      answer,
      description,
    }));

    await eventService.respondToEventDateOptionBulk(`${event.id}`, dateOptions);

    if (event!.customFields.length > 0) {
      await eventService.respondToEventPollBulk(`${event.id}`, customFieldResponses);
    }

    const state: SuccessPageState = { translationKey: 'page.events.invite.message.success' };
    navigate('/success', { state });
  };

  if (!event) return <></>;

  const { deadline } = event;

  const deadlineIsPassed = deadline ? new Date() > new Date(deadline) : false;

  return (
    <ResponsePageWrapper>
      <EventOverview event={event}>
        {!deadlineIsPassed && (
          <div className="flex flex-col gap-4">
            <div className="font-serif text-lg">{t('page.events.invite.datePickers.giveAvailability')}</div>

            {event.dateOptions.map((dateOption) => {
              const userId = getUserId();
              return dateOption.answers
                .filter((answer: DateOptionAnswer) => answer.id === userId)
                .map((dateOptionAnswer) => (
                  <EventSelectDateOption
                    key={dateOption.id}
                    dateOption={dateOption}
                    eventId={+event.id}
                    dateOptionAnswer={dateOptionsUpdate[dateOption.id] || dateOptionAnswer}
                    onSave={(__, dateOptionId, answer, description) => {
                      const update = { [dateOptionId]: { answer, description } };
                      setDateOptionsUpdate((prev) => ({ ...prev, ...update }));
                    }}
                  />
                ));
            })}

            {event.customFields.length > 0 && (
              <>
                <div className="font-serif text-lg">{t('page.events.invite.polls.title')}</div>
                <CustomFieldsForm
                  fields={event.customFields}
                  response={customFieldResponses}
                  setResponse={setCustomFieldResponses}
                />
              </>
            )}
          </div>
        )}
      </EventOverview>

      {!deadlineIsPassed && (
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button className="underline" onClick={() => navigate('/')}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
            {t('general.save')}
          </Button>
        </div>
      )}
    </ResponsePageWrapper>
  );
}

export default AuthenticatedEventRespondPage;

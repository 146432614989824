import { API_URL } from '../constants';
import { InboxBody } from '../types/inbox';
import {
  BusinessInvite,
  BusinessVersion,
  CommunityInvite,
  CommunityRequest,
  ConnectionInvite,
  CrmUserInvite,
  DataRequest,
  Introduction,
  Invite,
  LocalContactInvite,
  PersonalInvite,
} from '../types/invites';
import httpService from './httpService';

export function getInvite(linkId: string): Promise<Invite> {
  return httpService.get<Invite>('/invitationLink', { params: { linkId } }).then(
    ({ data }): Invite => ({
      ...data,
      profilePicture: data.profilePicture && `${API_URL}/${data.profilePicture}`,
    }),
  );
}

export function getConnectionInvite(invitationId: string): Promise<ConnectionInvite> {
  return httpService
    .get<ConnectionInvite>(`/connectionInvite/${invitationId}`)
    .then(({ data }): ConnectionInvite => data);
}

export function getBusinessInvite(invitationId: string, type: BusinessVersion): Promise<BusinessInvite> {
  return httpService
    .get<BusinessInvite>(`/business-invite/information/${type.toLocaleLowerCase()}/${invitationId}`)
    .then(({ data }): BusinessInvite => data);
}

export function getCommunityInvite(token: string): Promise<CommunityInvite> {
  return httpService
    .get<CommunityInvite>(`/communities/invite?token=${token}`)
    .then(({ data }): CommunityInvite => data);
}

export function getLocalContactInvite(token: string): Promise<LocalContactInvite> {
  return httpService
    .get<LocalContactInvite>(`/business/local-contact/invite/${token}`)
    .then(({ data }): LocalContactInvite => data);
}

export function getCrmUserInvite(token: string): Promise<CrmUserInvite> {
  return httpService.get<CrmUserInvite>(`/crm/invite?token=${token}`).then(({ data }): CrmUserInvite => data);
}

export function requestLocalContactInvite(token: string): Promise<void> {
  return httpService.post(`/business/local-contact/invite/request/${token}`).then(() => {});
}

export function getPersonalInvite(token: string): Promise<PersonalInvite> {
  return httpService.get<PersonalInvite>(`/personal/${token}`).then(({ data }): PersonalInvite => data);
}

export function getDataRequest(token: string): Promise<DataRequest> {
  return httpService.get<DataRequest>(`/data-request/${token}`).then(({ data }): DataRequest => data);
}

export function getCommunityContactInvite(token: string): Promise<CommunityInvite> {
  return httpService.get<CommunityInvite>(`/communities/invite/${token}`).then(({ data }): CommunityInvite => data);
}

export function getIntroductions(): Promise<Introduction[]> {
  return httpService.get<Introduction[]>('/introduction').then(({ data }): Introduction[] => data);
}

export function getCommunityRequest(token: string): Promise<CommunityRequest> {
  return httpService
    .get<CommunityRequest>(`/communities/connection-request/${token}`)
    .then(({ data }): CommunityRequest => data);
}

export function getRequestsAndInvites(): Promise<InboxBody> {
  return httpService.get<InboxBody>('/v3/requestsandinvites').then(({ data }): InboxBody => data);
}

import { LinkInfo } from '../types/misc';
import httpService from './httpService';

/**
 * Returns the data behind a redirect link including the
 * id of the link itself
 *
 * @param linkId the id of the link to fetch
 * @return `{ ...linkData, linkId }`
 */
export async function getLinkInfo(linkId: string): Promise<LinkInfo> {
  const linkData = await httpService.get<LinkInfo>(`/redirect/${linkId}`).then(({ data }): LinkInfo => data);
  return { ...linkData, linkId };
}

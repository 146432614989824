import React from 'react';
import classNames from 'classnames';
import { ProfileDataType, ProfileField } from '../../../types/profile';
import { profileDataTypeIcons, socialTypeIcons } from '../../../utils/iconUtils';
import { profileFieldToString } from '../../../utils/stringUtils';

interface FieldProps {
  field: ProfileField;
  handleClick?: (active: boolean, field: ProfileField) => void;
  active?: boolean;
}

export default function Field({ field, handleClick, active = false }: FieldProps): JSX.Element {
  return (
    <div
      key={field.id!}
      onClick={() => handleClick?.(active, field)}
      className={classNames(
        'mr-6 flex w-full cursor-pointer items-center justify-between rounded-lg border border-secondary-200 px-2 transition-all',
        {
          'bg-secondary-200': active,
        },
      )}>
      <div className="flex items-start gap-2 py-2">
        <div className="flex items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
          {field.dataType === ProfileDataType.SOCIAL && field.social
            ? socialTypeIcons[field.social?.socialType]
            : profileDataTypeIcons[field.dataType as keyof typeof profileDataTypeIcons]}
        </div>
        <label className="text-normal my-auto h-full text-sm text-primary-900">
          {field.dataType === ProfileDataType.SOCIAL
            ? field.social?.link
            : profileFieldToString(field)
                .split('\n')
                .map((x) => <p>{x}</p>)}
        </label>
      </div>
      {handleClick && (
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 rounded-full border-primary bg-secondary-50 text-primary-300 accent-primary-900 focus:ring-0 focus:ring-transparent"
          checked={active}
        />
      )}
    </div>
  );
}

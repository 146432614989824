import React, { useRef } from 'react';
import { HiTrash } from 'react-icons/hi2';
import { FaCamera } from 'react-icons/fa6';
import { FaPencilAlt } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import ApiImage from '../misc/ApiImage';
import { resizeImage } from '../../utils/formatUtils';

interface ImageInputProps {
  image: string | undefined;
  setImage: (image: string | undefined) => void;
  setImageAction?: (action: 'UPLOAD' | 'DELETE') => void;
  className?: string;
  iconClassName?: string;
}
export default function ImageInput({
  image,
  setImage,
  setImageAction = () => {},
  className,
  iconClassName,
}: ImageInputProps) {
  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files![0];
    if (!file) {
      return;
    }
    const resizedImage = await resizeImage(file, 768); // Change 1024 to your desired maximum width or height

    setImage(window.URL.createObjectURL(resizedImage));
    setImageAction('UPLOAD');
  };

  return !image ? (
    <>
      <button
        className={twMerge(
          'group h-full w-full rounded-lg border-2 border-dashed border-primary hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-10',
          className,
        )}
        type="button"
        onClick={(): void => inputFile.current?.click()}>
        <FaCamera className={twMerge('mx-auto my-auto text-primary group-hover:text-opacity-80', iconClassName)} />
      </button>
      <input type="file" className="hidden" onChange={handleUpload} accept="image/*" ref={inputFile} />
    </>
  ) : (
    <>
      <div className="relative w-fit">
        <ApiImage
          data-testid="profile-image"
          className={twMerge('flex-shrink-0 rounded-lg object-cover', className, 'border-0')}
          src={image}
          alt="profile"
        />
        <button type="button" className="absolute -right-2 -top-2 h-8 w-8 rounded-md bg-secondary-200">
          <HiTrash
            className="icon mx-auto h-5 w-5 rounded-md text-primary-900"
            onClick={() => {
              setImage(undefined);
              setImageAction('DELETE');
            }}
          />
        </button>
        <button type="button" className="absolute -bottom-2 -right-2 h-8 w-8 rounded-md bg-primary-900">
          <FaPencilAlt
            className="icon mx-auto h-5 w-5 text-secondary-50"
            onClick={(): void => inputFile.current?.click()}
          />
        </button>
      </div>
      <input type="file" className="hidden" onChange={handleUpload} accept="image/*" ref={inputFile} />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGift } from 'react-icons/fa6';
import classNames from 'classnames';
import { Label, MomentType, ProfileDataType, ProfileField } from '../../types/profile';
import { connectionService } from '../../services';
import { getInitials, profileFieldToString } from '../../utils/stringUtils';
import { sortProfileFields } from '../../utils/sortUtils';
import { momentTypeIcons, profileDataTypeIcons, socialTypeIcons } from '../../utils/iconUtils';
import PhoneLayout from '../layouts/PhoneLayout';
import ApiImage from '../misc/ApiImage';

interface ConnectionOverviewScreenProps {
  requesterId?: string;
  requesterAlias?: string;
}

export default function ConnectionOverviewScreen({
  requesterId,
  requesterAlias,
}: ConnectionOverviewScreenProps): JSX.Element {
  if (!requesterId || !requesterAlias) return <></>;
  const { t } = useTranslation();
  const [profilePicture, setProfilePicture] = useState<string>();
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);

  useEffect(() => {
    connectionService
      .getProfilePictureOfUser(requesterId)
      .then((i) => {
        setProfilePicture(i);
      })
      .catch(() => {});
    connectionService.getSharedDataByUser(requesterId).then((d) => {
      setProfileFields(d);
    });
  }, []);

  const filter = (label: Label | string) => {
    return sortProfileFields(
      profileFields
        .filter((f) => f.label === label)
        .filter(
          (f) =>
            f.dataType !== ProfileDataType.BUSINESSNAME &&
            f.dataType !== ProfileDataType.SOCIAL &&
            f.dataType !== ProfileDataType.MOMENT &&
            f.dataType !== ProfileDataType.BIRTHDATE,
        ),
    );
  };

  const businessName = profileFields.find((f) => f.dataType === ProfileDataType.BUSINESSNAME)?.description;
  const generalFields = filter(Label.GENERAL).concat(filter(''));
  const privateFields = filter(Label.PRIVATE);
  const workFields = filter(Label.WORK);

  const renderLabel = (label: Label, fields: ProfileField[]): JSX.Element => {
    if (!fields.length) return <></>;
    return (
      <div className="px-5">
        <p className="mb-2 text-sm text-primary">{t(`overview.connection.label.${label.toLocaleLowerCase()}`)}</p>
        {fields.map((f, i) =>
          renderField(profileDataTypeIcons[f.dataType], profileFieldToString(f), i !== fields.length - 1),
        )}
      </div>
    );
  };

  const renderMoments = (): JSX.Element => {
    const moments = profileFields.filter(
      (f) => f.dataType === ProfileDataType.MOMENT || f.dataType === ProfileDataType.BIRTHDATE,
    );
    if (!moments.length) return <></>;
    return (
      <div className="px-5 py-4">
        <p className="mb-2 text-sm text-primary">{t('overview.connection.moments.title')}</p>
        {moments.map((f, i) => {
          const [year, month, day] = f.moment?.date.split('-') || f.birthDate?.split('-') || [];

          return (
            <div className="flex w-full gap-2">
              <div className="flex h-fit w-fit items-center justify-center rounded-full bg-secondary p-2 text-secondary-50">
                {f.dataType === ProfileDataType.BIRTHDATE
                  ? profileDataTypeIcons[f.dataType]
                  : momentTypeIcons[f.moment?.momentType!]}
              </div>
              <div
                className={classNames('mb-[3px] w-full items-center border-secondary-200 py-2 pt-1 text-xs', {
                  'border-b': i !== moments.length - 1,
                })}>
                <p>
                  {f.moment?.momentType === MomentType.WEDDING
                    ? t('overview.connection.moments.wedding')
                    : f.dataType === ProfileDataType.BIRTHDATE
                      ? t('overview.connection.moments.birthdate')
                      : t('overview.connection.moments.birthdateOf', { name: f.moment?.name })}
                </p>
                <p>{`${day}-${month}-${year}`}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSocials = (): JSX.Element => {
    const socials = profileFields.filter((f) => f.dataType === ProfileDataType.SOCIAL);

    if (!socials.length) return <></>;
    return (
      <div className="px-5 py-4">
        <p className="mb-2 text-sm text-primary">Socials</p>
        {socials.map((f, i) =>
          renderField(socialTypeIcons[f.social?.socialType!], f.social?.link!, i !== socials.length - 1),
        )}
      </div>
    );
  };

  const renderField = (icon: JSX.Element, text: string, border: boolean): JSX.Element => (
    <div className="flex w-full gap-2">
      <div className="flex h-fit w-fit items-center justify-center rounded bg-secondary p-1 text-secondary-50">
        {icon}
      </div>
      <div
        className={classNames(
          'mb-[3px] w-full items-center border-secondary-200 py-2.5 pt-1.5 text-xs text-primary-900',
          { 'border-b': border },
        )}>
        {text}
      </div>
    </div>
  );

  return (
    <PhoneLayout className="bg-secondary-50">
      <div className="flex w-full flex-col gap-5 rounded-b-[40px] bg-secondary-200 px-5 py-5 pt-10">
        <div className="flex items-center gap-4">
          {profilePicture ? (
            <ApiImage
              src={profilePicture}
              alt={requesterAlias}
              className="h-[70px] w-[70px] rounded-[8px] object-cover"
            />
          ) : (
            <div className="flex h-[70px] w-[70px] items-center justify-center rounded-[8px] bg-secondary text-secondary-50">
              {getInitials(requesterAlias)}
            </div>
          )}
          <div className="flex flex-col">
            <p className="font-serif text-lg text-primary-900">{requesterAlias.replace('+', ' ')}</p>
            <p className="text-xs text-primary-900">{businessName}</p>
          </div>
        </div>
        <div className="mx-auto flex w-full justify-between">
          <button
            type="button"
            className="flex items-center justify-center gap-1 rounded-[32px] bg-primary px-2 py-2 text-xs text-secondary-50">
            <span className="text-md font-serif">?</span>
            Vraag gegevens
          </button>
          <button
            type="button"
            className="flex items-center justify-center gap-1 rounded-[32px] bg-primary px-3 py-2 text-xs text-secondary-50">
            <FaGift className="h-3 w-3" />
            Stuur kaartje
          </button>
        </div>
      </div>
      <div className="divide-y-4 divide-secondary-200 py-5">
        {renderLabel(Label.GENERAL, generalFields)}
        {renderLabel(Label.PRIVATE, privateFields)}
        {renderLabel(Label.WORK, workFields)}
        {renderMoments()}
        {renderSocials()}
      </div>
    </PhoneLayout>
  );
}

import React, { useEffect } from 'react';
import { HiUserGroup } from 'react-icons/hi2';
import { FaSearch } from 'react-icons/fa';
import classNames from 'classnames';
import { CommunityInvite } from '../../types/invites';
import { CommunityMember, CommunityRole } from '../../types/misc';
import { communityService } from '../../services';
import { getInitials } from '../../utils/stringUtils';
import PhoneLayout from '../layouts/PhoneLayout';
import ApiImage from '../misc/ApiImage';

interface CommunityOverviewScreenProps {
  invite?: CommunityInvite;
}

export default function CommunityOverviewScreen({ invite }: CommunityOverviewScreenProps): JSX.Element {
  if (!invite) return <></>;
  const [members, setMembers] = React.useState<CommunityMember[]>([]);
  const [search, setSearch] = React.useState<string>('');

  useEffect((): void => {
    communityService.getCommunityMembers(invite.communityId).then((res): void => setMembers(res));
  }, []);

  const memberList: Record<string, CommunityMember[]> = Array.from({ length: 26 }, (_, i) => {
    const letter = String.fromCharCode('A'.charCodeAt(0) + i);
    return {
      [letter]: members.filter(
        (m) =>
          m.firstName.toUpperCase().startsWith(letter) &&
          `${m.firstName}${m.lastName}`.toLowerCase().replace(' ', '').includes(search.toLowerCase().replace(' ', '')),
      ),
    };
  }).reduce(
    (acc, cur): Record<string, CommunityMember[]> => ({
      ...acc,
      ...(Object.values(cur)[0].length ? cur : {}),
    }),
    {},
  );

  return (
    <PhoneLayout className="bg-secondary-50">
      <div className="flex w-full flex-col gap-5 rounded-b-[40px] bg-secondary-200 px-5 py-5 pt-10">
        <div className="flex items-end gap-4">
          {invite.communityPicture && (
            <ApiImage
              src={invite.communityPicture}
              alt={invite.communityName}
              className="h-[80px] w-[80px] rounded-[8px] object-cover"
            />
          )}
          <div className="flex h-fit w-fit flex-col justify-center">
            <div className="flex h-fit w-fit gap-1">
              <HiUserGroup className="h-4 w-4" />
              <p className="text-xs text-primary-900">{invite.communitySize}</p>
            </div>
            <p className="font-serif text-lg text-primary-900">{invite.communityName}</p>
          </div>
        </div>
        <p className="text-sm text-primary-900">{invite.communityDescription}</p>
      </div>

      <div className="flex flex-col gap-2 px-5 py-5">
        <div className="relative mb-2 h-fit w-fit">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e): void => setSearch(e.target.value)}
            className="h-8 w-full px-5 py-2 pl-8 text-sm"
          />
          <FaSearch className="absolute left-2 top-4 text-primary" />
        </div>
        {Object.keys(memberList).map(
          (letter): JSX.Element => (
            <div key={letter} className="flex flex-col gap-1">
              <p className="font-medium text-primary">{letter}</p>
              {memberList[letter].map(
                (member, index): JSX.Element => (
                  <div className="flex w-full gap-2">
                    {member.picture ? (
                      <ApiImage src={member.picture} className="h-9 w-9 rounded object-cover" alt={member.firstName} />
                    ) : (
                      <div className="flex h-9 w-9 flex-shrink-0 items-center justify-center rounded bg-secondary p-1 text-secondary-50">
                        {getInitials(`${member.firstName}+${member.lastName}`)}
                      </div>
                    )}
                    <div
                      className={classNames(
                        'w-full items-center border-secondary-200 text-sm font-medium text-primary-900',
                        {
                          'border-b': index !== memberList[letter].length - 1,
                          'pt-1.5': member.role !== CommunityRole.ADMIN,
                          'pb-4': memberList[letter].length !== 1,
                        },
                      )}>
                      {member.role === CommunityRole.ADMIN && (
                        <p className="-mb-[5px] text-[10px] text-primary">Admin</p>
                      )}
                      {member.firstName} {member.lastName}
                    </div>
                  </div>
                ),
              )}
            </div>
          ),
        )}
      </div>
    </PhoneLayout>
  );
}

import React, { ComponentProps } from 'react';
import Select from 'react-select';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_200,
  COLOR_SECONDARY_50,
} from '../../../constants';

interface DropdownOption<T> {
  label: string;
  value: T;
}

type DropdownSelectProps<T> = {
  options: DropdownOption<T>[];
  setValue: (value: T | undefined) => void;
  value: T | undefined;
} & Omit<ComponentProps<typeof Select<DropdownOption<T>>>, 'value'>;

export default function DropdownSelect<T>({
  options,
  setValue,
  value,
  styles: stylesProp,
  ...props
}: DropdownSelectProps<T>) {
  return (
    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`,
          borderRadius: '15px',
          borderWidth: '2px',
          color: COLOR_PRIMARY_900,
          backgroundColor: `${COLOR_SECONDARY_200}80`,
          boxShadow: 'none',
          height: '50px',
          fontWeight: '500',
          fontSize: '16px',
          '&:hover': {
            borderColor: state.isFocused ? COLOR_PRIMARY_300 : `${COLOR_SECONDARY_200}80`,
          },
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          height: 'hug',
        }),
        option: (baseStyles, { isSelected }) => {
          return {
            ...baseStyles,
            backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
            color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
            '&:hover': {
              backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
              color: COLOR_PRIMARY_900,
            },
          };
        },
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
        }),
      }}
      options={options}
      value={options.find((o) => o.value === value)}
      onChange={(o) => setValue(o?.value)}
      {...props}
    />
  );
}
